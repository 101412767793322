html{
	@if $scale {
		font-size: resize(10px);
	} @else {
		font-size: 10px;
	}
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	background-color: $main-bg-colour;
	overflow-x: clip;
	//scroll-behavior: smooth;

	@media (min-width: 2560px + 1){
		font-size: 17.7777px;
	}

	@media (max-width: $md){
		overflow-x: clip;

		&.menu-open{
			overflow: hidden;
		}
	}

	@media (max-width: $md){
		font-size: 10/(390/100)*1vw;
	}

	// &#BTT{
	// 	margin-top: 0 !important;
	// }

	&:-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&:-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{

		&:-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&:-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

body{
	color: $colour;
	@include font-size(18);
	@include line-height(18,27);
	font-weight: 400;
	position: relative;
	overflow: clip;

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,24);
	}

	&:-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&:-webkit-scrollbar{
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{

		&:-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&:-webkit-scrollbar{
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: 1.4em;
	@include font-size(18);
	@include line-height(18,27);

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,24);
	}

	a{
		font-size: inherit;
		text-decoration: underline;
		font-weight: inherit;
		color: inherit;

		&:hover,
		&:focus-visible{
			text-decoration: none;
			color: inherit;
		}
	}

	strong{
		font-weight: 700;
		color: inherit;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	margin-bottom: .5em;
	font-weight: 700;
}

h1{
	@include font-size(89.76);
	@include line-height(89.76,89.76);
	letter-spacing: -.05em;
	margin-bottom: .52em;

	@media (max-width: $md){
		@include font-size(48);
		@include line-height(48,54);
		margin-bottom: .5em;
	}
}

h2{
	@include font-size(56);
	@include line-height(56,56);
	letter-spacing: -.02em;
	margin-bottom: .56em;

	@media (max-width: $md){
		@include font-size(36);
		@include line-height(36,49);
		letter-spacing: -.05em;
		margin-bottom: .66em;
	}
}

h3{
	@include font-size(40);
	@include line-height(40,48);
	font-weight: 400;
	letter-spacing: -.02em;

	@media (max-width: $md){
		@include font-size(29);
		@include line-height(29,40);
		letter-spacing: -.03em;
	}
}	

h4{
	@include font-size(24);
	@include line-height(24,32);

	@media (max-width: $md){
		@include font-size(20);
		@include line-height(20,35);
		letter-spacing: -.02em;
		font-weight: 400;
	}
}

h5{
	@include font-size(22);
	@include line-height(22,30);

	@media (max-width: $md){
		@include font-size(20);
	}
}

h6{
	@include font-size(20);
	@include line-height(20,28);

	@media (max-width: $md){
		@include font-size(18);
	}
}

p{
	@include font-size(18);
	@include line-height(18,27);

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,24);
	}

	&.small{
		@include font-size(16);
		@include line-height(16,20);
		font-weight: 300;

		@media (max-width: $md){
			@include font-size(14);
		}
	}
}

html body .main-wrapper.main-wrapper.main-wrapper.main-wrapper.main-wrapper section p{

	&:has( + ul){
		margin-bottom: 1.28em;
	}
}

sub, 
sup{
	font-size: 25.9%;
	font-size: 51.5625%;
	font-weight: inherit;
	margin-left: -.1em;
}

sup{
	top: -.71em;
}

sub{
	bottom: .3em;
}

small{
	font-size: 70%;
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

button,
a{
	font-size: inherit;
	color: $colour;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: underline;
	text-underline-position: under;
	text-underline-offset: -.08em;

	&:before,
	&:after,
	*:before,
	*:after,
	*{
		transition: $time;
	}

	&:hover,
	&:focus-visible{
		text-decoration: none;
	}
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

mark{
	background-color: $yellow;
	text-transform: uppercase;
	padding: .71em 1.1em;
	display: inline-block;

	@media (max-width: $md){
		padding: .76em 1.15em;
	}

	strong{
		color: $black;
	}
}

strong{
	font-weight: 800;
}

blockquote{
	@include font-size(18);
	@include line-height(18,27);
	margin: 0 0 .8em;
	text-transform: none;
	padding: 0;
	font-style: normal;
	color: $colour;
	position: relative;
	z-index: 1;
	font-family: $main-font;
	font-weight: 400;

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,24);
	}

	~ .by{
		@include font-size(14);
		@include line-height(14,22);
		text-align: center;
		font-weight: 600;
		display: flex;
		align-items: center;
	}

	p{
		font-size: inherit!important;
		line-height: inherit!important;
		font-family: inherit!important;
		font-weight: inherit!important;
		margin-bottom: 0!important;
		letter-spacing: inherit!important;
		display: inline;
	}
}

hr{
	border: 0;
	width: 100%;
	height: .1rem;
	min-height: 1px;
	background-image: linear-gradient(to right, $blue-dark 33%, rgba($blue-dark,0) 0%);
	background-size: .6rem 100%;
	margin: 6.5rem 0 6.3rem;
	background-position: 0 100%;

	@media (max-width: $lg){
		margin: 3rem 0 3rem;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

// br{

// 	@media (max-width: $md){
// 		display: none;
// 	}
// }

address{
	font-style: normal;
	@include font-size(18);
	@include line-height(18,27);
	color: $colour;
	margin-bottom: 1.5em;

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,24);
	}
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	font-size: 0;
	margin-bottom: 1.55em;

	li,
	dt,
	dd{
		@include font-size(18);
		@include line-height(18,27);
		margin-bottom: 0;

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,24);
		}

		&.small{
			@include font-size(16);
			@include line-height(16,20);
			font-weight: 300;

			@media (max-width: $md){
				@include font-size(14);
			}
		}

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 1rem;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(18);
	@include line-height(18,27);
	margin-bottom: 1.35em;
}

ul{
	list-style-position: inside;
	padding-left: 0;

	li{
		padding-left: 1.5em;
		margin-bottom: 0em;
		list-style: none;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			left: .6em;
			top: .7em;
			width: .35em;
			aspect-ratio: 1/1;
			border-radius: 50%;
			background-color: currentColor;
		}

		ul{
			margin-top: 1em;
			margin-bottom: 1em;

			li{

				// &:before{
				// 	border-radius: 0;
				// 	height: .1em;
				// 	top: .75em;
				// }
			}
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0;
	counter-reset: section;

	li{
		list-style: none;
		position: relative;
		padding-left: 1.5em;
		margin-bottom: 1.33em;

		&:nth-of-type(1n + 10){

			&:before{
				content: counter(section)". ";
			}
		}

		&:before{
			counter-increment: section;
			content: counter(section)". ";
			min-width: 1em;
			display: inline-block;
			position: absolute;
			top: 0em;
			left: .1em;
			font-size: inherit;
			line-height: inherit;
			color: currentColor;

			@media (max-width: $md){
				left: 0;
			}
		}

		ol{
			margin-top: 1em;
			margin-bottom: 1em;

			li{
				padding-left: 2.3em;

				&:before{
					content: counter(section , lower-alpha)". ";
					left: .1em;
				}
			}
		}
	}
}

.wpml-ls ul,
ul.what-menu,
ul.wc-item-meta,
ul.order_details,
ul.filter-items,
ul.small-footer-menu,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

html body table:not(.ui-datepicker-calendar){
	margin-bottom: 1.77778em;
	border: none;
	width: 100%!important;
	background-color: transparent;
	text-align: left;
	table-layout: fixed;
	@include font-size(18);
	@include line-height(18,27);
	overflow: hidden;
	border-radius: 2.4rem 2.4rem 0 0;

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,24);
	}
	
	*{
		vertical-align: top;
	}

	&:not(:has(thead)) tbody tr:first-of-type,
	thead{

		td,
		th{
			padding: 2.15rem 2.3rem;
			border-bottom: .1rem solid #DBDBDB;
			border-right: .1rem solid #DBDBDB;
			font-family: $main-font;
			color: $grey;
			font-weight: 400;
			color: #222222;
			@include font-size(20);
			background-color: $blue-back;

			&:last-of-type{
				border-right: none;
			}
		}
	}

	tbody{

		tr{

			&:last-of-type{

				td{
					border-bottom: none;
				}
			}
		}

		td{
			padding: 1.55rem 1.5rem;
			border-bottom: .1rem solid #DBDBDB;
			border-right: .1rem solid #DBDBDB;
			color: $grey;
			@include font-size(18);

			&:last-of-type{
				border-right: none;
			}
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(14);
		@include line-height(14,16);
		font-weight: 400;
		color: $colour;
		
		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 1.1rem;

			@media (max-width: $md){
				padding-top: .3rem;
			}
		}
	}
}

svg,
p.p-img a,
p.p-img img,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 2.5em 0 1.2em;
	}

	&.alignright {
		float:right;
		margin: 2.5em 0 1.2em 1.2em;
	}

	&.alignleft {
		float:left;
		margin: 2.5em 1.2em 1.2em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 2.5em auto 1.2em;
	}
}

a img.alignright {
	float:right;
	margin: 2.5em 0 1.2em 1.2em;
}

a img.alignleft {
	float:left;
	margin: 2.5em 1.2em 1.2em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 2.5em auto 1.2em;
}

::selection {
	background: darken($colour, 40%);
	color: $white;
}

::-moz-selection {
	background: darken($colour, 40%);
	color: $white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

input[type="radio" ]:focus-visible + span:before,
input[type="radio" ]:focus-visible + .wpcf7-list-item-label :before,
input[type="radio" ]:focus-visible + label:before,
input[type="checkbox"]:focus-visible + span:before,
input[type="checkbox"]:focus-visible + .wpcf7-list-item-label :before,
input[type="checkbox"]:focus-visible + label:before,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
input[name][name][name]:focus-visible,
textarea[name][name][name]:focus-visible,
select[name][name][name]:focus-visible{
	border-color: $green-darkest!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	margin-left: 0px;
	text-align: left;
}

pre{
	font-size: resize(10px);
	@include font-size(10);
}

.main-wrapper{
	padding-top: 1px;
	margin-top: -1px;
	padding-bottom: 0;
	width: 100%;
	width: 100dvw;

	section{
		//overflow: hidden;
		overflow-x: clip;
	}
}

.inner{
	position: relative;
}

img.full-bg,
.full-bg{
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

img.full-bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}