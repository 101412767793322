//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: absolute;
	top: 0;
	left: 0;

	@media (max-width: $md){
		
		.pop-open &{
			z-index: 0;
			transition: 0s;
		}
	}
}

body{

	&.scroll-up,
	&.scroll-down{

	}
}

a.skip{
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;

	&:focus{
		top: 0;
		left: 0;
		width: auto;
		height: auto;
		color: $colour;
		text-shadow: 0 0 .1rem white;
		z-index: 11111111;
		-webkit-transition: 0s 0s;
		transition: 0s 0s;
	}
}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	z-index: 2;
	transition: 0s;
	display: flex;
	align-items: flex-end;
	width: 25.63086rem;
	transition: $time;
	position: relative;
	overflow: hidden;

	@media (max-width: $md){
		width: 12.9rem;
		z-index: 3;
		position: relative;
	}
	
	svg,
	img{
		width: 100%;
	}
}

.menu-top-outer{
	transition: $time;

	@media (max-width: $md){
		
		.menu-open &{

			.container{
				position: relative;

				&:before{
					opacity: 1;
					visibility: visible;
					transition: $time $time;
				}
			}
		}
	}

	.row{
		padding-top: 6.5rem;
		padding-bottom: 6.5rem;

		@media (max-width: $md){
			padding-top: 2.9rem;
			padding-bottom: 2.9rem;
		}
	}
	
	.col-12{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.left,
		.middle,
		.right{
			display: flex;
			align-items: center;
		}

		.left{
			justify-content: flex-start;
			width: 15rem;
		}

		.middle{
			justify-content: center;
			width: 25.63086rem;

			@media (min-width: $md + 1){
				padding-top: .5rem;
			}
		}

		.right{
			justify-content: flex-end;
			width: 15rem;
		}

		.menu-btn{
			background-color: $blue;
			@include font-size(20);
			padding: .901rem 1.8rem .701rem;
			border-radius: 2.3rem;
			text-transform: uppercase;
			font-weight: 700;
			font-family: $alt-font;
			text-decoration: none;
			position: relative;
			z-index: 2;

			@media (max-width: $md){
				@include font-size(12);
				padding: .801rem 1.7rem .601rem;
			}

			&:hover,
			&:focus-visible{
				color: $blue-darker;
			}
		}

		.icon-links{
			background-color: $blue;
			padding: 1.026rem 1.6rem;
			display: flex;
			align-items: center;
			border-radius: 2.3rem;
			position: relative;
			z-index: 2;

			@media (max-width: $md){
				background-color: transparent;
				padding: 0;
			}

			a{

				@media (max-width: $md){
					aspect-ratio: 1/1;
					border-radius: 3.2rem;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $blue;
				}

				&:has(+ a){
					margin-right: 1.4rem;

					@media (max-width: $md){
						margin-right: 1rem;
					}
				}

				&.favourites{
					width: 2.55rem;
					aspect-ratio: 1/1;

					@media (max-width: $md){
						width: 3.2rem;

						svg{
							width: 2rem!important;
						}
					}
				}

				&.basket{
					width: 2.4rem;
					aspect-ratio: 1/1;
					position: relative;
					text-decoration: none;

					@media (max-width: $md){
						width: 3.2rem;

						svg{
							width: 2rem!important;
						}
					}

					&.has{

						.cart-contents-count{
							opacity: 1;
						}
					}

					.cart-contents-count{
						background-color: rgba($white, 1);
						box-shadow: 0 0 1rem rgba($black, .2);
						width: 1.5rem;
						aspect-ratio: 1/1;
						position: absolute;
						bottom: -.25rem;
						right: -.25rem;
						text-align: center;
						line-height: 1.4rem;
						@include font-size(10);
						font-weight: 700;
						border-radius: 50%;
						opacity: 0;
						color: $black;
						
					}
				}

				&:hover,
				&:focus-visible{
					
					svg{

						*{
							fill: $blue-darker;
						}
					}
				}

				svg{
					width: 100%;

					*{
						color: $black;
					}
				}
			}
		}
	}
}

.mob-only.mob-only{
	width: 100%;

	@media (min-width: $md + 1){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

#mainMenu{
	display: block;
	align-items: center;
	flex-wrap: wrap;
	position: absolute;
	top: -1.6rem;
	left: 0;
	width: 55.5rem;
	border-radius: 2.4rem;
	background-color: $blue-back;
	padding: 12.7rem 1.6rem 3.2rem;
	box-shadow: 0 .4rem 1rem rgba(black, .1);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	@media (max-width: $md){
		left: .2rem;
		width: calc(100% - .4rem);
		padding: 7.8rem 1.6rem 3rem;
		max-height: calc(100dvh - 1.2rem - 1.2rem);
		overflow: auto;
	}

	.menu-open &{
		display: block;
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transition: $time;
	}

	.follow{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 4.5rem 1.5rem 0;

		@media (max-width: $md){
			padding: .6rem 0rem 0;

			span{
				@include font-size(18);
				letter-spacing: -.02em;
			}
		}

		.social-menu{

			a{
				color: $black;

				&:hover,
				&:focus-visible{
					color: $blue-dark;
				}
			}
		}
	}

	// @media (max-width: $md){
	// 	margin: 0 auto;
	// 	position: fixed;
	// 	top: 0rem;
	// 	left: 0;
	// 	width: 100%;
	// 	max-width: 100%;
	// 	// transition: $time;
	// 	// padding: 0rem 2rem;
	// 	flex-direction: column;
	// 	flex-wrap: nowrap;
	// 	display: flex;
	// 	justify-content: flex-start;
	// 	// overflow: hidden;
	// 	z-index: -10;
	// 	max-height: 100dvh;
	// 	height: 100dvh;
	// 	background-color: $blue-dark;
	// 	transform: translateX(100%);
	// 	transition: .68s;
	// 	padding: 17.4rem 9rem 2rem 4rem;
	// 	align-items: flex-start;
	// 	background-image: url(../images/menu-back.svg);
	// 	background-repeat: no-repeat;
	// 	background-size: 100% auto;
	// }
}

@media (min-width: $md + 1){

	// #mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-ancestor,
	#mainMenu .main-menu .current-page-ancestor,
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent,
	#mainMenu .main-menu .current-menu-item{

		> a{
			
			&:after{
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0;
	padding: 0;
	@include font-size(0);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		width: 100%;
		margin-bottom: 1.7rem;

		@media (min-width: $md + 1){

			&.mob-only{
				display: none;
			}

			&:hover,
			&:focus-visible{
				
				> a{
					background-color: $blue-dark;
					color: $black;
				}
			}
		}

		@media (max-width: $md){
			position: relative;
			
			&.open{

				.sub-menu.sub-menu{
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
					max-height: 9999px;
					margin-bottom: 0rem;
				}
			}
		}

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		// &.menu-item-has-children{
		// 	flex-wrap: wrap;
		// 	position: relative;

		// 	@media (min-width: $md + 1){
		// 		padding-right: 2.4rem;

		// 		&:after{
		// 			content: '';
		// 			position: absolute;
		// 			display: block;
		// 			aspect-ratio: 11/6.005;
		// 			top: 50%;
		// 			right: 0;
		// 			width: 1.1rem;
		// 			background-image: url(../images/menu-arrow.svg);
		// 			background-repeat: no-repeat;
		// 			background-size: 1.1rem auto;
		// 			transform: translateY(-50%) rotateX(0deg);
		// 			transition: $time;
		// 		}
				
		// 		&:hover,
		// 		&:focus-visible{

		// 			&:after{
		// 				transform: translateY(-50%) rotateX(180deg);
		// 			}
					
		// 			> .sub-menu{
		// 				opacity: 1;
		// 				visibility: visible;
		// 				pointer-events: auto;
		// 			}
		// 		}

		// 		li{

		// 			&.green a{

		// 				&:hover,
		// 				&:focus-visible{
		// 					background-color: $green;
		// 				}
		// 			}
				
		// 			&.yellow a{
				
		// 				&:hover,
		// 				&:focus-visible{
		// 					background-color: $yellow;
		// 				}
		// 			}
				
		// 			&.blue a{
				
		// 				&:hover,
		// 				&:focus-visible{
		// 					background-color: $blue;
		// 				}
		// 			}
				
		// 			&.purple a{
				
		// 				&:hover,
		// 				&:focus-visible{
		// 					background-color: $purple;
		// 				}
		// 			}
				
		// 			&.pink a{
				
		// 				&:hover,
		// 				&:focus-visible{
		// 					background-color: $pink;
		// 				}
		// 			}
		// 		}
		// 	}

		// 	@media (max-width: $md){
		// 		padding-right: 4.6rem;
		// 		position: relative;
		// 		width: 100%;
		// 		display: block;

		// 		&:after{
		// 			content: '';
		// 			position: absolute;
		// 			width: 1.3rem;
		// 			aspect-ratio: 13/8;
		// 			right: 1.5rem;
		// 			top: 2.6rem;
		// 			transform: rotateX(0deg);
		// 			background-image: url(../images/menu-arrow.svg);
		// 			background-repeat: no-repeat;
		// 			background-size: 100% auto;
		// 			transition: $time;
		// 			backface-visibility: visible!important;
		// 			display: block!important;
		// 		}

		// 		&.open{

		// 			&:after{
		// 				transform: rotateX(180deg);
		// 			}
		// 		}
		// 	}

		// 	.sub-menu{
		// 		position: absolute;
		// 		top: 100%;
		// 		left: -2rem;
		// 		width: 19.1rem;
		// 		max-width: 19.1rem;
		// 		pointer-events: none;
		// 		opacity: 0;
		// 		visibility: hidden;
		// 		border-radius: 2rem;
		// 		background-color: $white;
		// 		box-shadow: 0 0 1rem rgba($black,.05);
			
		// 		@media (max-width: $md){
		// 			position: static;
		// 			transform: none;
		// 			max-height: 0;
		// 			overflow: hidden;
		// 			width: 100%;
		// 			max-width: 100%;
		// 			background-color: transparent;
		// 			border-radius: 0;
		// 		}

		// 		li{
		// 			width: 100%;
		// 			text-align: left;
		// 			justify-content: flex-start;

		// 			@media (max-width: $md){
						
		// 				&:first-of-type{
		// 					margin-top: 1.4rem;
		// 				}

		// 				&:last-of-type{
		// 					margin-bottom: 1.4rem;
		// 				}
		// 			}

		// 			@media (min-width: $md + 1){
		// 				padding-left: 3.3rem;
		// 				padding-right: 1rem;

		// 				&:first-of-type{
		// 					margin-top: 2rem;
		// 				}

		// 				&:last-of-type{
		// 					margin-bottom: 2rem;
		// 				}
						
		// 			}
					
		// 			+ li{
		// 				margin-left: 0;
		// 			}

		// 			@media (max-width: $md){
		// 				width: 100%;
		// 				text-align: left;
		// 			}

		// 			// @media (min-width: $md + 1){
						
		// 			// 	&:hover,
		// 			// 	&:focus-visible{

		// 			// 		> a{
		// 			// 			color: $blue-dark;
		// 			// 		}
		// 			// 	}
		// 			// }
		// 		}

		// 		a{
		// 			font-weight: 500;
		// 			color: $black;
		// 			padding: .25rem 0;
		// 			justify-content: flex-start;
		// 			margin: 0;
		// 			padding: 0rem 3.2rem 0rem 0;

		// 			@media (max-width: $md){
		// 				@include font-size(22);
		// 				@include line-height(22,46);
		// 				padding: 0rem 5.7rem 0rem 0;

		// 				&:has([data-colour="green"]){
		// 					color: $green;

		// 					span{
		// 						background-image: url(../images/sub-green.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="pink-bright"]){
		// 					color: $pink;

		// 					span{
		// 						background-image: url(../images/sub-brand.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="pink"]){
		// 					color: #9c8c94;

		// 					span{
		// 						background-image: url(../images/sub-pink.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="blue"]){
		// 					color: #6f87a5;

		// 					span{
		// 						background-image: url(../images/sub-blue.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="coral"]){
		// 					color: #ce4743;

		// 					span{
		// 						background-image: url(../images/sub-coral.svg);
		// 					}
		// 				}

		// 				span{
		// 					content: '';
		// 					position: absolute;
		// 					top: 55%;
		// 					right: 0;
		// 					background-repeat: no-repeat;
		// 					transform: translateX(-1rem) translateY(-50%);
		// 					aspect-ratio: 13/6;
		// 					width: 2.1rem;
		// 					background-size: 2.1rem auto;
		// 					transition: $time;
		// 					display: block;
		// 				}
		// 			}

		// 			@media (min-width: $md + 1){
		// 				color: $black!important;
		// 				position: relative;
		// 				display: block;

		// 				&:has([data-colour="green"]){

		// 					span{
		// 						background-image: url(../images/sub-green.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="pink-bright"]){
		// 					color: $pink;

		// 					span{
		// 						background-image: url(../images/sub-brand.svg);
		// 					}
		// 				}


		// 				&:has([data-colour="pink"]){

		// 					span{
		// 						background-image: url(../images/sub-pink.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="blue"]){

		// 					span{
		// 						background-image: url(../images/sub-blue.svg);
		// 					}
		// 				}

		// 				&:has([data-colour="coral"]){

		// 					span{
		// 						background-image: url(../images/sub-coral.svg);
		// 					}
		// 				}

		// 				span{
		// 					content: '';
		// 					position: absolute;
		// 					top: 52%;
		// 					right: 0;
		// 					background-repeat: no-repeat;
		// 					transform: translateX(-1rem) translateY(-50%);
		// 					aspect-ratio: 13/6;
		// 					width: 1.3rem;
		// 					background-size: 1.3rem auto;
		// 					opacity: 0;
		// 					visibility: hidden;
		// 					transition: $time;
		// 					display: block;
		// 				}
						
		// 				&:hover,
		// 				&:focus-visible{

		// 					span{
		// 						opacity: 1;
		// 						visibility: visible;
		// 						transform: translateX(0rem) translateY(-50%);
		// 					}
		// 				}
		// 			}

		// 			&:after{
		// 				display: none;
		// 			}
		// 		}
		// 	}

		// 	@media (min-width: $md + 1){
				
		// 		&:hover,
		// 		&:focus-visible{
					
		// 			.sub-menu{
		// 				pointer-events: auto;
		// 				opacity: 1;
		// 				visibility: visible;
		// 				transition: $time;
		// 			}
		// 		}
		// 	}
		// }

		// [href="#"]{
		// 	pointer-events: none;
		// }

		> a{
			@include font-size(67.34);
			@include line-height(67.34,67.34);
			letter-spacing: 0;
			letter-spacing: -.02em;
			width: auto;
			color: $black;
			text-decoration: none!important;
			font-weight: 700;
			position: relative;
			font-family: $main-font;
			padding: 1.3rem .9rem 2.7rem;
			z-index: 2;
			border-radius: 2.4rem;
			background-color: $blue;
			width: 100%;
			display: block;

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					background-color: $blue-dark;
					color: $black;
				}
			}

			@media (max-width: $md){
				@include font-size(48);
				@include line-height(48,48);
				padding: 2.6rem .7rem 3.4rem;
				letter-spacing: -.05em;
			}
		}
	}
}


// .single-sectors,
// .single-services{

// 	.main-menu{

// 		#menu-item-11{

// 			> a{

// 				&:after{
// 					opacity: 1;
// 					visibility: visible;
// 				}
// 			}
// 		}
// 	}
// }