.breadcrumb{
	color: $colour;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@include font-size(16);
	@include line-height(16,20);
	font-weight: 300;
	margin-bottom: 2.2rem;

	span,
	a{
		@include font-size(16);
		@include line-height(16,20);
		font-weight: 300;
	}

	span{
		color: $colour;
		display: inline;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	a{
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		color: $colour;
		position: relative;
		margin-right: .5rem;

		&:hover,
		&:focus-visible{
			color: $blue-darker;
		}
	}

	> span > span ~ span{
		margin-left: .5rem;
	}

	> a{
		margin-left: 0;
	}

	.breadcrumb_last{
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		position: relative;
	}
}

.breadcrumb-section{

	@media (max-width: $md){
		display: none;
	}

	.row{
		padding-top: 3.8rem;
		padding-bottom: 6.2rem;

		@media (max-width: $md){
			padding-top: 1.4rem;
			padding-bottom: 1.4rem;
		}
	}
}