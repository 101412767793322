//!!SCALE SETTINGS
$scale: true; //false, true
$vw-viewport: 1920; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 32!default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1728; //most common container width

//!!COLOURS
$transparent:		transparent;

$red:				#d40000;

$green:				#D3FFAC;
$green-dark:		#C1FA8E;
$green-darkest:		#52b000;

$orange:			#FFE5CE;
$orange-dark:		#FFD8AB;

$yellow:			#F2FFA3;
$yellow-dark:		#E6F87C;

$pink:				#F8D7FE;
$pink-dark:			#E4CCFF;

$blue:				#D7F4FF;
$blue-dark:			#BAEAFC;
$blue-darker:		#2D86AA;

$blue-back:			#ECF6FA;

$white:				#FFFFFF;
$black:				#1A1616;

$grey:				#838080;
$grey-light:		#F7F7F7;
 
$colour: 			$black;
$main-bg-colour:	$white;

//!!FONTS

@font-face {
	font-family: 'Synthese';
	src: url('../fonts/Synthese-Light.woff2') format('woff2'),
		url('../fonts/Synthese-Light.woff') format('woff'),
		url('../fonts/Synthese-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Synthese';
	src: url('../fonts/Synthese-Regular.woff2') format('woff2'),
		url('../fonts/Synthese-Regular.woff') format('woff'),
		url('../fonts/Synthese-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Synthese';
	src: url('../fonts/Synthese-Bold.woff2') format('woff2'),
		url('../fonts/Synthese-Bold.woff') format('woff'),
		url('../fonts/Synthese-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}




@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../fonts/CabinetGrotesk-Regular.woff2') format('woff2'),
		url('../fonts/CabinetGrotesk-Regular.woff') format('woff'),
		url('../fonts/CabinetGrotesk-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
		url('../fonts/CabinetGrotesk-Bold.woff') format('woff'),
		url('../fonts/CabinetGrotesk-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../fonts/CabinetGrotesk-Black.woff2') format('woff2'),
		url('../fonts/CabinetGrotesk-Black.woff') format('woff'),
		url('../fonts/CabinetGrotesk-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}





$font-synthese:		'Synthese', sans-serif;
//light - 300
//regular - 400
//bold - 700

$font-cabinet:		'Cabinet Grotesk', sans-serif;
//bold - 700
//black - 900

$main-font:			$font-synthese;
$alt-font:			$font-cabinet;

//!!SITE TRANSITIONS
$time:				.4s;
$wayTime:			.5s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$lg:				992px; //992px - 1px
$md:				769px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px