
//!!FORM
::-webkit-input-placeholder{
	@include font-size(16);
	color: $grey;
	letter-spacing: inherit;
}
 
:-moz-placeholder{ /* Firefox 18- */
	@include font-size(16);
	color: $grey;
	letter-spacing: inherit;
}

::-moz-placeholder{  /* Firefox 19+ */
	@include font-size(16);
	color: $grey;
	letter-spacing: inherit;
}

:-ms-input-placeholder{
	@include font-size(16);
	color: $grey;
	letter-spacing: inherit;
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 2.3rem;
$label-bottom-margin: 2.5rem;

input,
input.input-text,
textarea,
.fake-select,
select{
	display: block;
	padding: 2.55rem 2.5rem;
	@include font-size(16);
	@include line-height(15,15);
	font-weight: 500;
	color: $grey;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: 0;
	border: .1rem solid #E5E4E4;
	background-color: transparent;
	border-radius: .8rem;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	letter-spacing: -.02em;
}


textarea{
	height: 23.9rem;
	min-height: 23.9rem;
	min-width: 100%;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

//
//!!SELECT START
//
.fake-select,
select{
	padding-right: 4.9rem;
	appearance: none;
	background-image: url(../images/select-icon.svg);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 1.16667rem auto;
	line-height: 7.465rem - .1rem - .1rem;
	height: 7.565rem;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	background-position: right 2.7rem top 50%;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: .05rem;
		padding-bottom: .05rem;
		color: $black;
	}
}
//
//!!SELECT END
//

legend,
label{
	display: block;
	@include font-size(20);
	@include line-height(20,28.32);
	font-weight: 400;
	font-family: $main-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	color: $black;
	width: 100%;
	align-self: flex-start;

	.gfield_required{
		
		&::before{
			content: ' *';
			color: $black;
			margin-left: .05em;
			display: inline-block;
		}
		
		span{
			display: none;
		}
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend %btn;
}

.submit-con{
	display: flex;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;

	

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 6rem!important;
		position: relative;
		cursor: pointer;
		@include font-size(16);
		line-height: 2rem;
		@include inline;
		margin-top: 0;
		color: $grey;
		font-weight: 400;
		text-align: left!important;
		width: 100%;
		margin-bottom: 0;

		a{
			text-decoration: underline;

			&:hover,
			&:focus-visible{
				text-decoration: none;
			}
		}

		&:before{
			content: '';
			position: absolute;
			width: 2.0rem;
			height: 2.0rem;
			left: 2.4rem;
			top: .0rem;
			border-radius: .6rem;
			//border-radius: 2rem;
			border: .1rem solid #E5E4E4;
		}

		&:after{
			content: '';
			position: absolute;
			width: 2.0rem;
			height: 2.0rem;
			left: 2.4rem;
			top: .0rem;
			border-radius: .6rem;
			border: .1rem solid $blue-darker;
			opacity: 0;
			transition: $time;
			background-image: url(../images/tick.svg);
			background-repeat: no-repeat;
			background-position: 45% 55%;
			background-size: 1.1333rem auto;
			background-color: $blue-back;
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + span,
	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 50%;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!GRAVITY START
//

.gform_wrapper{
	position: relative;
	display: flex;
	flex-direction: column;

	.gform_heading{
		order: 9999;
		margin-top: 2.9rem;

		p{
			@include font-size(14);
			@include line-height(14,18);
			width: 55rem;
		}
	}

	.gform_submission_error{
		display: none;
	}

	fieldset{
		padding: 0;
		margin: 0;
		border: none;
	}

	.gform_fields{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;

		.gfield_visibility_hidden{
			opacity: .5;
			visibility: hidden;
			pointer-events: none;
			max-height: 0;
			overflow: hidden;
			margin: 0!important;
		}
	
		.gfield,
		.gfield--width-half,
		.gfield--width-third{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: $input-bottom-margin;
			place-items: flex-start;

			@media (max-width: $lg){
				margin-bottom: 3.3rem;
			}

			@media (max-width: $md){
				margin-bottom: 2.35rem;
			}
		}

		.gfield{
			width: 100%;
			align-self: flex-start;

			&.spacer{
				margin-bottom: 0;
			}
		}

		.gfield--width-half{
			width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));

			// @media (min-width: $md + 1){
			// 	width: calc(50% - 2.1rem);
			// }	

			@media (max-width: $md){
				width: 100%;
			}


		}

		.gfield--width-third{
			width: 32%;
			width: calc(33.333% - (#{$grid-gutter-widthWO/10+rem}/2));

			// @media (min-width: $md + 1){
			// 	width: calc(33.333% - 2rem);
			// }

			@media (max-width: $md){
				width: calc(50% - (#{$grid-gutter-widthWO/10+rem}/2));
				//width: calc(50% - 1rem);

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $sm){
				width: 100%;
			}
		}

		.gfield{

			&.gfield--type-textarea + .gfield--type-checkbox{
				margin-top: 1.4rem;
			}

			&.hidden_label{
				
				// legend,
				// label{
				// 	
				// }

				.gfield_label{
					display: none;
				}
			}

			.ginput_container{
				width: 100%;
			}

			&.gfield--type-consent{
				border: none;
				padding: 0;
				margin-left: 0;
				margin-right: 0;

				.gfield_label{
					display: none;
				}
			}
		}

		fieldset.gfield{
			position: relative;
		}
	}

	.gfield.gfield--type-html{
		display: block;
	}

	.gfield--type-radio,
	.gfield--type-checkbox{

		&.gfield_error{
			
			input[type="radio"],
			input[type="checkbox"]{

				& + span,
				& + .wpcf7-list-item-label,
				& + label{
		
					&:before{
						border-color: $red;
					}
				}
			}
		}
	}

	.gform_fileupload_rules{
		display: none;
	}

	.gform_footer{
		margin-top: 1rem;
	}

	.gfield--input-type-radio,
	.gfield--input-type-checkbox{

		legend.gfield_label{
			margin-bottom: 2.7rem;

			@media (max-width: $md){
				margin-bottom: 2.2rem;
			}
		}
	}

	.gfield_checkbox,
	.gfield_radio{
		//display: flex;
		flex-wrap: wrap;

		.gform-field-label{
			margin-bottom: 2rem;
		}
		
		.gchoice{
			line-height: 2rem;
			//display: flex;
			align-items: center;
			margin-bottom: $input-bottom-margin;
			//margin-bottom: 1rem;

			@media (max-width: $sm){
				min-height: 0;
				margin-bottom: 1.2rem;
			}

			label{
				align-self: center;
				margin-bottom: 0;
			}

			&:has(+ .gchoice){
				// margin-right: 2.2rem;

				// @media (max-width: $md){
				// 	margin-right: 1.2rem;
				// }
			}
		}
	}

	[type="file"]{
		border-color: transparent;
		padding: 0;
		border-radius: 0;
		@include font-size(15);

		&:hover,
		&:focus-visible{
			
			&::file-selector-button{
				background-color: $yellow;
				color: $black!important;
			}
		}
	}

	[type="file"]::file-selector-button{
		@extend %btn;
		padding-left: 2.6rem;
		padding-right: 2.6rem;
		margin-right: 1.2rem;

		
	}

	.ginput_preview_list{
		@include font-size(14);
		font-family: $main-font;
		position: absolute;
		top: 100%;
		width: 100%;

		.gfield_fileupload_percent{
			margin-left: .4em;
		}

		.gform_delete_file{
			text-decoration: none;
			@include font-size(15);
			margin-left: 0em;
			line-height: 1.1;

			*{
				@include font-size(15);
			}
		}
	}

	.gfield_validation_message{
		@include font-size(14);
		color: $red;
		font-family: $alt-font;
		font-weight: 500;
		width: 100%;
		margin-top: .7rem;

		&.validation_message--hidden-on-empty{
			display: none;
		}
	}

	.gform_ajax_spinner{
		margin: 1rem auto 0;
	}

	.gform_submission_error{
		@include font-size(15);
		color: $red;
		color: $white;
		font-weight: 500;
		font-family: $main-font;

		@media (max-width: $md){
			@include font-size(15);
		}
	}
}

.gfield--type-honeypot{
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

.gform_confirmation_message{
	font-weight: 500;
	@include font-size(15);
	font-family: $main-font;
	color: $green;
}

//
//!!GRAVITY END
//

.gdatepicker_with_icon{
	background-image: url(../images/cal.svg);
	background-repeat: no-repeat;
	background-size: 1.3125rem auto;
	background-position: 100% 50%;
	padding-right: 2.5rem;

	+ .ui-datepicker-trigger{
		display: none;
	}
}

#ui-datepicker-div{
	background-color: $main-bg-colour;
	border: .1rem solid $colour;
	width: 24rem;
	z-index: 9999!important;

	.ui-datepicker-header{
		padding: 1rem 1rem;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.ui-datepicker-prev,
		.ui-datepicker-next{
			padding: .5rem .5rem;
			background-color: $black;
			color: $white;
			text-decoration: none;
			@include font-size(14);
			line-height: 1.4;
			font-weight: 500;
			font-family: $alt-font;
			text-transform: uppercase;
			margin-bottom: 1rem;
			text-align: center;

			border: .1rem solid $blue;
			background-color: transparent;
			color: $white;
			font-weight: 500;
			letter-spacing: .1em;
			border-radius: 5rem;
			padding: .3rem 1rem;
			margin-bottom: .5rem;
			width: calc(50% - .5rem);

			span{
				color: $white;
			}

			&:hover,
			&:focus-visible{
				background-color: $colour;
				color: $white;
				border-color: $black;
				background-color: $black;

				span{
					color: $white;
				}
			}
		}

		.ui-datepicker-title{
			width: 100%;
		}

		.ui-datepicker-month{
			margin-bottom: 1rem;

			margin-bottom: .5rem;
		}

		select{
			line-height: 3.2rem;
			height: 3.3rem;
			@include font-size(14);
		}
	}

	.ui-datepicker-calendar{
		width: 100%;
		text-align: center;
		margin-bottom: 0;

		thead{

			tr{
				background-color: $blue;

				th{
					padding: 0rem 0rem;
					border-top: .1rem solid $colour;
					border-right: .1rem solid $colour;
					width: calc(100% / 7)!important;
					height: 4.553rem;
					line-height: 4.553rem;
					height: 3.409rem;
					line-height: 3.409rem;
					font-family: $alt-font;
					color: $white;
					@include font-size(14);
					line-height: 1.1;
					font-weight: 500;

					&:last-of-type{
						border-right: none;
					}
				}
			}
		}

		tbody{

			tr{
				background-color: $white;

				td{
					padding: 0rem .1rem;
					border-top: .1rem solid $colour;
					border-right: .1rem solid $colour;
					width: calc(100% / 7)!important;
					height: 4.553rem;
					line-height: 4.553rem;
					height: 3.409rem;
					line-height: 3.409rem;
					font-family: $alt-font;
					position: relative;
					@include font-size(13);
					font-weight: 500;

					a{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						text-decoration: none;

						&.ui-state-hover.ui-state-active,
						&.ui-state-active{
							background-color: $colour;
							color: $white;
						}

						&.ui-state-hover{
							background-color: $white;
							color: $blue;
						}
					}

					&:last-of-type{
						border-right: none;
					}
				}
			}
		}
	}
}
