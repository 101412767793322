//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -.85rem;
	max-width: calc(100% + .85rem + .85rem);
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 -.6rem;
		max-width: calc(100% + .6rem + .6rem);
	}

	.arrow-link,
	.btn{
		margin: 0 .85rem .85rem;

		@media (max-width: $md){
			margin: 0 .6rem 1.7rem;
		}

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	font-size: 2.4rem!important;
	line-height: 1.3;
	font-weight: 900;
	color: $black!important;
	text-decoration: none!important;
	font-family: $alt-font;
	text-align: center;
	padding: 1.473rem 3.2rem;
	padding: 1.573rem 3.2rem 1.373rem 3.2rem;
	background-color: $blue;
	border: .1rem solid $blue;
	border-radius: 4.4rem;
	box-shadow: none;
	letter-spacing: .05em;
	cursor: pointer;
	position: relative;
	transition: $time;
	z-index: 2;
	justify-content: center;
	text-transform: uppercase;

	@media (max-width: $md){
		font-size: 2rem!important;
	}

	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $blue-dark;
		border-color: $blue-dark;
		color: $black!important;
	}

	&.disabled,
	&:disabled{
		opacity: .25;
		pointer-events: none;
	}
}

.btn{

	&.arrow{
		padding: 1.534rem 3.2rem;

		@media (max-width: $md){
			padding: 1.13rem 2.4rem;
		}

		&:hover,
		&:focus-visible{
			background-color: $blue-dark;
			border-color: $blue-dark;
			
			i{

				&:after{
					transform: translate(-50%, -50%) rotate(-100deg);
				}
			}
		}

		i{
			width: 5.6rem;
			aspect-ratio: 1/1;
			background-image: url(../images/btn-arrow.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: 2.4rem auto;
			position: relative;
			margin-left: 2.3rem;
			margin-right: .7rem;

			@media (max-width: $md){
				width: 4rem;
				margin-left: 1.1rem;
				margin-right: .3rem;
				background-size: 1.8rem auto;
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(0deg);
				background-image: url(../images/btn-i.svg);
				background-repeat: no-repeat;
				background-size: 100%;
				width: 100%;
				height: 100%;
				z-index: -1;
				transition: $time;
			}
		}
	}

	&.wc-forward,
	&[name="add-to-cart"],
	&.download{
		//padding: 1.534rem 3.2rem;

		&:hover,
		&:focus-visible{
			background-color: $blue-dark;
			border-color: $blue-dark;
		}

		i{
			content: '';
			width: 2.4rem;
			aspect-ratio: 1/1;
			background-image: url(../images/basket.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: 2.4rem auto;
			position: relative;
			margin-left: 1.5rem;
			margin-right: -.1rem;
		}
	}

	&.download{

		i{
			background-image: url(../images/download.svg);
		}
	}

	&.small{
		font-size: 1.6rem!important;
	}

	&.big{
		font-size: 3.2rem!important;
		padding: .45rem 1.1rem;
		font-weight: 500;
		border-radius: 2.8rem;

		@media (max-width: $md){
			font-size: 2rem!important;
			padding: .75rem 1.6rem;
			letter-spacing: -.02em;
		}
	}
}