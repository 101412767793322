footer{
	position: relative;
	z-index: 3;

	// .container{

	// 	@media (min-width: $md + 1){
	// 		max-width: calc(100% - 6.2rem - 6.2rem + 2.4rem);
	// 	}
	// }

	.footer-top-outer{
		background-color: #3B4448;

		@media (max-width: $md){
			border-radius: 0;
		}

		*{
			color: $white;

			em{
				color: $blue-dark;
			}
		}

		.row{
			padding-top: 9.6rem;
			padding-bottom: 11.6rem;

			@media (max-width: $md){
				padding-top: 4.8rem;
				padding-bottom: 4.7rem;
			}
		}

		.let-side,
		.social-side{
			align-self: center;
			margin-bottom: 8.4rem;

			@media (max-width: $md){
				margin-bottom: 2.5rem;
			}

			svg{
				width: 66.8rem;

				@media (max-width: $md){
					width: 34.3rem;
				}

				@media (min-width: $md + 1){
					
					&:nth-of-type(2){
						display: none;
					}
				}

				@media (max-width: $md){
					
					&:nth-of-type(1){
						display: none;
					}
				}
			}
		}

		.social-side{

			@media (max-width: $md){
				margin-bottom: 2.8rem;
			}
		}

		address{
			@include font-size(20);
			@include line-height(20,28);

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,24);
			}
		}

		.address-side{

			@media (max-width: $md){
				margin-bottom: 2.4rem;
			}
		}

		.menu-side{

			@media (max-width: $md){
				margin-bottom: 2.4rem;
			}
		}

		.footer-menu{
			@include font-size(20);
			@include line-height(20,28);
			margin-bottom: 0;

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,24);
			}

			li{
				@include font-size(20);
				@include line-height(20,28);

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,24);
				}
			}

			a{
				@include font-size(20);
				@include line-height(20,28);
				width: auto;
				color: $white;
				position: relative;
				font-family: $main-font;
				padding: 0;
				text-decoration: underline;

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,24);
				}

				&:hover,
				&:focus-visible{
					color: $blue-dark;
				}
			}
		}

		.info-side{

			a{
				@include font-size(20);
				@include line-height(20,28);
				text-decoration: underline;

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,24);
				}

				&:hover,
				&:focus-visible{
					color: $blue-dark;
				}
			}
		}

		.copy{
			@include font-size(20);
			@include line-height(20,28);
			margin-top: 1.35em;

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,24);
				margin-top: 1.55em;
			}
		}
	}
}