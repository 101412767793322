.woocommerce-page{

	.main-wrapper{

		// ::-webkit-input-placeholder {
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $md){
		// 		@include font-size(16);
		// 	}
		// }
		 
		// :-moz-placeholder { /* Firefox 18- */
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $md){
		// 		@include font-size(16);
		// 	}
		// }
		
		// ::-moz-placeholder {  /* Firefox 19+ */
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $md){
		// 		@include font-size(16);
		// 	}
		// }
		
		// :-ms-input-placeholder {
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $md){
		// 		@include font-size(16);
		// 	}
		// }

		// input,
		// textarea{
		// 	background-color: #f4f4f4;
		// 	border-color: #f4f4f4;
		// 	@include font-size(24);
		// 	color: $black;

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}
		
		// 	@media (max-width: $md){
		// 		@include font-size(16);
		// 	}
		// }

		// input[type="radio"],
		// input[type="checkbox"]{
		// 	display: none;
		// 	border: none!important;
		// 	margin: 0!important;

		// 	& + span,
		// 	& + .wpcf7-list-item-label,
		// 	& + label{
		// 		padding-left: 49px!important;
		// 		@include font-size(24);
		// 		line-height: 39px!important;

		// 		@media (max-width: $b2){
		// 			@include font-size(20);
		// 		}
			
		// 		@media (max-width: $md){
		// 			@include font-size(16);
		// 		}

		// 		&:before{
		// 			width: 39px;
		// 			height: 39px;
		// 			left: 0;
		// 			top: 0;
		// 			border: 1px solid #f4f4f4;
		// 			background-color: #f4f4f4;
		// 		}

		// 		&:after{
		// 			width: 27px;
		// 			height: 27px;
		// 			left: 6px;
		// 			top: 6px;
		// 		}
		// 	}
		// }
	}
}

.woocommerce-notices-wrapper{
	width: 100%;
}

.woocommerce.woocommerce{

	.woocommerce-notices-wrapper{
	
		.woocommerce-message{
			max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
			margin: 19.808rem auto 1em;

			@media (max-width: $md){
				max-width: calc(100% - 4.8rem);
				margin: 8.998rem auto 1em;
			}
			
		}
	}
}

.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce,
.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page{

	.lost_reset_password{
		width: 100%;
	}

	div.woocommerce{
		max-width: $base-width + $grid-gutter-width;
		padding-right: $grid-gutter-width/2;
		padding-left: $grid-gutter-width/2;
		margin: 0 auto;
		padding-top: 0;
		padding-bottom: 8.9em;
		//padding-top: 30.9rem;

		@media (min-width: $md + 1){
			max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
			padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
			padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
		}

		@media (max-width: $md){
			padding-right: 2.4rem;
			padding-left: 2.4rem;
			padding-bottom: 7rem;
		}

		.text-hero-section{

			.container{
				padding: 0;
			}
		}
	}

	.form-row{
		margin-bottom: 0;
		padding: 0;

		input.input-text,
		textarea{
			margin-bottom: $input-bottom-margin;
		}

		label{
			width: 100%;
			text-align: left;
		}
	}

	form .form-row-first,
	form .form-row-last,
	form .form-row-first,
	form .form-row-last{

		@media (max-width: $sm){
			width: 100%;
		}
	}

	.woocommerce-form-login{
		
		.form-row{
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.password-input{
				width: 100%;
			}
		}
	}

	.woocommerce-password-hint{
		margin-bottom: $input-bottom-margin;
	}

	[for=mailchimp_woocommerce_newsletter][for=mailchimp_woocommerce_newsletter][for=mailchimp_woocommerce_newsletter]{
		line-height: 1.3;
	}

	[for=mailchimp_woocommerce_newsletter],
	.woocommerce-form-login__rememberme{
		margin-bottom: $input-bottom-margin;
		display: inline-block;

		span{
			margin-bottom: 0px!important;
		}
	}

	[type="submit"]{
		margin-bottom: $input-bottom-margin;
	}

	form .form-row .required{
		color: $colour;
	}

	form .show-password-input{
		top: 1.4em;
	}

	form .show-password-input.display-password::after,
	form .show-password-input.display-password::after{
		color: $red;
	}

	form.checkout_coupon,
	form.login,
	form.register{
		border: none;
		padding: 0;
		margin-top: 0;
	}

	.woocommerce{

		input, 
		input.input-text, 
		textarea, 
		select{
			//background-color: #EAEBEC;
			//border-color: #EAEBEC;
		}
	}

	.select2-dropdown.select2-dropdown{
		max-width: none;
		//border: 1px solid #EAEBEC;
		//background-color: #EAEBEC;
		border-radius: 0;
		//top: calc(100% - 4rem + 1px);
		box-shadow: none;
		@include font-size(16);
		border: .1rem solid #E5E4E4;
		border-radius: .8rem;

		&.select2-dropdown--above{
			top: calc(0% + 0px);
		}

		.select2-results{
			padding: 0 2.5rem 2.0rem;
		}

		ul{

			li{
				margin-bottom: 5px;
				padding: 0;
				line-height: 1.5;
			}
		}

		.select2-search__field{
			
		}

		.select2-search{
			padding: 2.0rem 2.5rem;
		}

		.select2-results__option[data-selected=true]{
			background-color: transparent;
		}

		.select2-results__option--highlighted[data-selected]{
			color: $blue;
			background-color: transparent;
			transition: $time;
		}

		.select2-search__field{
			margin-bottom: 0;
			border: none;
			border-bottom: .1rem solid #E5E4E4;
			padding: 0;
			border-radius: 0;
			background-color: white;
			padding: .5rem 0;
		}
	}

	.select2-container.select2-container{
		margin-bottom: $input-bottom-margin;
		height: auto;
		border: none;
		background-color: transparent;
		border-radius: .8rem;
		max-width: 100%;
		line-height: 7.465rem - .1rem - .1rem;
		height: 7.565rem;
		border: .1rem solid #E5E4E4;

		&--open{
			
			.select2-selection__arrow.select2-selection__arrow{
				background-image: url(../images/select-icon.svg);
				background-size: 1.16667rem auto;
				background-position: right 2.7rem top 50%;
			}
		}

		&--focus{
			
		}

		.select2-selection--single.select2-selection--single{
			border: none;
			background-color: transparent;
		}

		.select2-selection--single{
			height: 4rem;
			border: .1rem solid #E5E4E4;
			background-color: transparent;
			border-radius: 0;
			line-height: 4rem;
		}

		.select2-selection__rendered{
			line-height: 7.465rem - .1rem - .1rem;
			height: 7.565rem;
			padding-right: 4.9rem;
			padding-left: 2.5rem;
			color: $grey;
			@include font-size(16);
		}

		.select2-selection__arrow{
			line-height: 7.465rem - .1rem - .1rem;
			height: 7.565rem;
			background-image: url(../images/select-icon.svg);
			background-repeat: no-repeat;
			background-position: 100% 50%;
			background-size: 1.16667rem auto;
			width: 4.5rem;
			top: 0;
			right: 0;
			background-position: right 2.7rem top 50%;

			b{
				display: none;
			}
		}
	}

	.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text{
		
		&:after{
			content: ''!important;
			margin: 0!important;
		}
	}

	.woocommerce-button{

		+ .woocommerce-button{
			margin-left: .4rem;
		}
	}

	#respond input#submit,
	a.button,
	button.button,
	input.button{
		@extend %btn;
		min-width: 18.7rem;
		text-transform: uppercase;

		&:disabled{
			pointer-events: none;
		}
	}

	legend{
		padding-left: 1.0rem;
		padding-right: 1.0rem;
		margin-left: -1.0rem;
		font-weight: 700;
		text-align: left;
	}

	fieldset{
		margin: 0 0 2.0rem;
		padding: 1.8rem 1.8rem;
		border-color: $grey-light;
		border-style: solid;
		border-width: .2rem;
	}

	address{
		@include font-size(20);
		@include line-height(20,30);

		@media (max-width: $md){
			@include font-size(16);
		}
	}

	.woocommerce-Address-title{
		display: flex;
		position: relative;
		margin-bottom: 1.2rem;

		h3{
			width: 100%;
			padding-right: 5.0rem;
			margin-bottom: 0;
			@include font-size(26);
			@include line-height(26,25);
			font-family: $main-font;
			font-weight: 700;
		}

		.edit{
			position: absolute;
			bottom: .25em;
			right: 0;

			&:hover,
			&:focus-visible{
				text-decoration: none;
			}
		}
	}

	a.remove{
		color: $red!important;
		font-weight: 400;
		line-height: 2.8rem;
		font-size: 0!important;
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 4rem;
		border: .2rem solid $black;
		position: relative;
		cursor: pointer;
		transition: $time;


		&:hover,
		&:focus-visible{
			background-color: $blue;
		}

		&:before,
		&:after{
			content: '';
			height: .2rem;
			width: 1.115rem;
			border-radius: 2rem;
			background-color: $black;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			transition: $time;
		}

		&:after{
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $red;
			color: $white!important;

			&:before,
			&:after{
				background-color: $red;
			}
		}
	}

	.coupon.coupon.coupon{
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $md){
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		input,
		.button{
			margin-bottom: 1.0rem;
		}

		//!!FORM
		::-webkit-input-placeholder{
			@include font-size(20);

			@media (max-width: $md){
				@include font-size(16);
			}
		}
		
		:-moz-placeholder{ /* Firefox 18- */
			@include font-size(20);

			@media (max-width: $md){
				@include font-size(16);
			}
		}

		::-moz-placeholder{  /* Firefox 19+ */
			@include font-size(20);

			@media (max-width: $md){
				@include font-size(16);
			}
		}

		:-ms-input-placeholder{
			@include font-size(20);

			@media (max-width: $md){
				@include font-size(16);
			}
		}

		input{
			min-width: 34.99rem;
			margin-right: 1.61rem;
			padding: .1rem 2.2rem;
			@include font-size(20);
			// background-color: #EAEBEC;
			// border-color: #EAEBEC;

			@media (max-width: $md){
				padding: 2.55rem 2.5rem;
				@include font-size(16);
			}

			@media (min-width: $md + 1){
				margin-bottom: 0;
			}

			@media (max-width: $md){
				min-width: 0;
				width: 100%;
				margin-right: 0;
			}
		}

		.button{
			width: auto;
			position: relative;

			&:hover,
			&:focus-visible{
				background-color: $blue-dark;
				border-color: $blue-dark;
			}

			// &:after{
			// 	content: '';
			// 	width: 2.4rem;
			// 	aspect-ratio: 1/1;
			// 	background-image: url(../images/basket.svg);
			// 	background-repeat: no-repeat;
			// 	background-position: 50% 50%;
			// 	background-size: 2.4rem auto;
			// 	position: relative;
			// 	margin-left: 1.5rem;
			// 	margin-right: -.1rem;
			// }
		}
	}

	table.cart{
		//background-color: #EAEBEC;
	}

	ul.wc_payment_methods.wc_payment_methods.wc_payment_methods.wc_payment_methods.wc_payment_methods.wc_payment_methods{

		.wc_payment_method.wc_payment_method:before{
			display: none!important;
		}

	}

	table.shop_table:not(.cart){

		&.my_account_orders,
		&.order_details.order_details{
			border-radius: 2.4rem 2.4rem 0 0!important;
			overflow: hidden!important;
			border-top: none!important;

			thead{

				th{
					color: $black;
					@include font-size(20);
				}
			}

			tfoot{
				color: $grey;

				*{
					color: $grey!important;
					font-weight: 400!important;
				}
			}
			
			th,
			td{
				border-bottom: .1rem solid #DBDBDB!important;

				+ th{
					border-left: .1rem solid #DBDBDB!important;
				}

				&:first-of-type:has(+ td){
					border-left: none!important;
				}
			}
		}

		&.my_account_orders.my_account_orders.my_account_orders.my_account_orders.my_account_orders{

			tbody{

				th + td{
					border-left: .1rem solid #DBDBDB!important;
				}
			}
		}

		tr{
			background-color: transparent;
		}

		th{
			font-weight: 400!important;
			color: $grey;
			@include font-size(18);
		}
		
		td{
			border-left: .1rem solid #DBDBDB;
			@include font-size(18);
		}
	}

	.woocommerce{

		h2{
			width: 100%;
		}
	}

	table.shop_table{
		border: none;
		padding: 0;
		margin: 0;

		html#BTT &{
			width: 100%!important;
		}

		tr,
		td,
		th{
			border: none;
		}

		tbody{

			td,
			th{
				border-bottom: .1rem solid #DBDBDB;
				
				&[colspan="6"]{
					border-bottom: none;
					padding-top: 6.3rem;
				}
			}
		}
	}

	table.shop_table td{

		@media (max-width: $md){
			padding: .6rem .8rem;
		}
	}

	.wc-proceed-to-checkout{
		padding-bottom: 0;

		.button{
			margin-bottom: 0;
		}
	}

	table{
		text-align: left;

		th,
		tr,
		td{
			text-align: left;

			&.product-name.product-name.product-name.product-name.product-name.product-name,
			&.product-total.product-total.product-total.product-total.product-total.product-total{
				text-align: left;
			}
		}

		tbody{

			*{
				font-weight: 500;
			}

			tr{

				&:nth-of-type(odd){
					//background-color: #F3F3F3;
					background-color: transparent;

					th,
					td{
						background-color: transparent;
					}
				}

				&:nth-of-type(even){
					//background-color: #EAEBEC;
					background-color: transparent;

					th,
					td{
						background-color: transparent;
					}
				}

				&.cart_item + tr:not(.cart_item){
					background-color: white;
					padding-right: 0;
					padding-left: 0;

					td{
						padding-right: 0;
						padding-left: 0;
						text-align: right;
					}
				}
			}
		}

		.quantity.quantity.quantity{

			[type="number"]{
				width: 12.6rem;
				text-align: left;
				background-color: transparent;
				margin: 0 0;
				//padding-left: 1.0rem;
			}
		}
		
	}

	.cart_totals{

		table{

			td{
				width: 22.0rem!important;

				@media (max-width: $md + 1){
					width: 100%!important;
				}
			}

			.order-total .woocommerce-Price-amount{
				font-weight: 700;
			}
		}
	}

	table{
		@include font-size(20);
		border-radius: 2.4rem 2.4rem 0 0;

		tr{
			border-bottom: none!important;
		}

		td,
		th{
			@include font-size(20);
			padding: 2.15rem 2.3rem;
		}

		&.cart{
			border-radius: 2.4rem 2.4rem 0 0!important;
			overflow: hidden;
			border-top: none!important;

			thead{

				th{
					padding: 4.9rem 2.3rem;
					font-weight: 400;
				}
			}
		}

		td{
			@include font-size(18);
			padding: 2.4rem 2.3rem;
		}

		.cart-subtotal,
		.order-total{

			td{
				color: $grey;
				font-weight: 400;

				*{
					font-weight: 400;
				}
			}
		}

		small{
			color: lighten(grey, 5%);
			@include font-size(12);
			line-height: 1.4;
			display: block;

			.amount.amount.amount.amount.amount.amount{
				font-weight: 400;
			}

			*{
				@include font-size(12);
				line-height: 1.4;
			}
		}

		.product-subtotal{
			font-weight: 400;
		}

		&.woocommerce-cart-form__contents{


			@media (max-width: $md){

				tbody{

					tr:first-of-type{

						td:first-of-type{
							border-top: none!important;
						}
					}
				}
			}
		}

		.actions{

			@media (max-width: $md){
				padding-left: 0;
				padding-right: 0;
			}

			.button{
				width: auto;

				&:disabled{

					&:hover,
					&:focus-visible{
						background-color: $grey;
						border-color: $grey;
						color: $red!important;
					}
				}
			}
		}

		.cart_item{

			@media (max-width: $md){
				padding: 0;
			}

			dl.variation{

				dd{
					margin-bottom: .3em;

					&:last-of-type{
						margin-bottom: 0;
					}
				}

				p{
					line-height: 1.2;
				}
			}
		}

		ul.wc-item-meta{

			li{
				margin-bottom: 0;

				&:last-of-type{
					margin-bottom: 0;
				}
			}

			p{
				line-height: 1.2;
			}
		}

		.product-remove{

			@media (max-width: $md){
				padding-bottom: 0;
			}
		}

		.product-thumbnail + .product-name{

			@media (max-width: $md){
				border-top: none!important;
			}
		}

		img{
			margin: 1rem auto;
			width: 10.297rem;
			min-width: 10.297rem;
		}

		.product-remove{
			
			@media (min-width: $md + 1){
				width: 7.4rem!important;
			}

			@media (max-width: $md + 1){
				width: 100%!important;
			}
		}

		.product-thumbnail,
		.product-price,
		.product-quantity,
		.product-subtotal{
			//width: 5rem!important;

			@media (max-width: $md + 1){
				width: 100%!important;
			}
		}

		.product-thumbnail{

			@media (min-width: $md + 1){
				width: 34.4rem!important;
			}

			a{
				aspect-ratio: 134.04/128;
				border-radius: 2.4rem;
				background-color: $orange;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 13.404rem;
				margin-left: auto;
			}
		}

		.product-price{

			@media (min-width: $md + 1){
				width: 29.2rem!important;
			}
		}

		.product-quantity{

			@media (min-width: $md + 1){
				width: 29.7rem!important;
			}
		}

		.product-subtotal{

			@media (min-width: $md + 1){
				width: 28.2rem!important;
			}
		}

		.product-quantity{

			@media (max-width: $md + 1){
				display: flex;
				align-items: center;
				justify-content: space-between;

			}
		}

		// input{
		// 	border-bottom: 1px solid $grey-light!important;

		// 	&:focus-visible{
		// 		border-bottom: 1px solid $red!important;
		// 	}
		// }

		.screen-reader-text{
			height: 0;
			width: 0;
		}

		&.shop_table{

			th,
			td{
				//background-color: transparent;
			}
		}

		.shipping-calculator-button{
			white-space: pre;
			margin-top: 0;
		}

		.product-name{

			a{
				color: $grey;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					text-decoration: underline;
				}
			}
		}
	}

	.shop_table{

		// td:last-of-type{
		// 	width: 50px!important;
		// 	width: 210px!important;

		// 	label:before{
		// 		margin-top: 2px;
		// 	}

		// 	@media (max-width: $md + 1){
		// 		width: 100%!important;

		// 		&.product-total{
		// 			width: 50px!important;
		// 			width: 210px!important;

		// 			label:before{
		// 				margin-top: 2px;
		// 			}
		// 		}
		// 	}
		// }
	}

	.woocommerce ul#shipping_method li{
		padding-left: 0;
	}

	.woocommerce-checkout-review-order-table.woocommerce-checkout-review-order-table{
		border-radius: 2.4rem 2.4rem 0 0!important;
		overflow: hidden!important;
		border-top: none!important;

		th,
		td{
			border-bottom: .1rem solid #DBDBDB!important;

			+ th{
				border-left: .1rem solid #DBDBDB!important;
			}

			&:first-of-type:has(+ td){
				border-left: none!important;
			}
		}

		.cart-subtotal,
		.order-total{

			td:last-of-type{

				@media (max-width: $md + 1){
					width: 15.0rem!important;
				}
			}
		}
	}

	.woocommerce-checkout{

		#createaccount{

			+ span:last-of-type{
				margin-bottom: 1.8rem;
			}
		}

		#terms{

			+ span{

				&:after{
					content: '*';
					color: $red;
					font-weight: 700;
					margin-left: .25em;
				}
			}

			~ span.required{
				display: none;
			}
		}

		// h3{
		// 	color: $blue;
		// 	border-bottom: .2rem solid $grey-light;
		// 	padding-bottom: 1.0rem;
		// }

		h3#ship-to-different-address{
			float: none;
			min-height: 1.2728em;
			display: flex;
			align-items: flex-end;

			span{
				margin-bottom: 0;
			}

			label{
				margin-bottom: 0;
			}
		}

		.input-checkbox{
			display: none;
		}

		#order_review_heading{
			padding-top: 2.0rem;
		}
	}

	form .form-row.woocommerce-validated .select2-container,
	form .form-row.woocommerce-validated input.input-text,
	form .form-row.woocommerce-validated select{
		border-color: $green;
	}

	form .form-row.woocommerce-invalid .select2-container,
	form .form-row.woocommerce-invalid input.input-text,
	form .form-row.woocommerce-invalid select{
		border-color: $red;
	}

	form .form-row.woocommerce-invalid label{
		color: $red;
	}

	.woocommerce-checkout-payment{
		background-color: transparent!important;

		.input-checkbox{
			display: none;
		}

		.place-order{
			padding: 2.0rem 0 0!important;
		}

		.woocommerce-terms-and-conditions-wrapper{
			margin-bottom: 2.0rem;
		}
	}

	tbody{

		.button{
			margin-top: .5rem;
			margin-bottom: .5rem;

			&[name="update_cart"]{
				
				// &:after{
				// 	content: '';
				// 	width: 2.4rem;
				// 	aspect-ratio: 1/1;
				// 	background-image: url(../images/basket.svg);
				// 	background-repeat: no-repeat;
				// 	background-position: 50% 50%;
				// 	background-size: 2.4rem auto;
				// 	position: relative;
				// 	margin-left: 1.5rem;
				// 	margin-right: -.1rem;
				// }
			}
		}
	}

	tabel.order_details tbody{

		@media (max-width: $md){

			.product-name{
				width: 100%!important;
			}
		}
	}

	.woocommerce-customer-details address{
		border: .1rem solid #DBDBDB;
		border-radius: 0;
		line-height: 1.4em;

		p{
			line-height: 1.5em;
		}
	}

	form .form-row-first, 
	form .form-row-last, 
	form .form-row-first, 
	form .form-row-last{
		width: calc(50% - (#{$grid-gutter-width}/2));

		@media (min-width: $md + 1){
			width: calc(50% - 2.75rem);
		}

		@media (max-width: $md){
			width: 100%;
		}
	}

	.col2-set{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.col-1,
		.col-2{
			padding-left: 0;
			padding-right: 0;
			width: 50%;
			flex: 0 0 50%;
			max-width: 50%;

			@media (max-width: $md){
				width: 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (min-width: $md + 1){
			.col-1{
				padding-right: calc(2.75rem);
			}

			.col-2{
				padding-left: calc(2.75rem);
			}
		}
	}

	#add_payment_method #payment div.payment_box::before, 
	.woocommerce-cart #payment div.payment_box::before, 
	.woocommerce-checkout #payment div.payment_box::before{
		margin: -1em 0 0 1.8rem;
	}
}

.woocommerce-checkout{

	.woocommerce &{
		padding-bottom: 3.0rem;
	}
}

.woocommerce td.product-name .wc-item-meta .wc-item-meta-label.wc-item-meta-label{
	float: none;
}

.woocommerce-error.woocommerce-error,
.woocommerce-info.woocommerce-info,
.woocommerce-message.woocommerce-message{
	padding: 1.0rem 1.6rem 1.0rem 1.6rem+1.6rem+2rem;
	border-top-width: 0;
	margin-top: 0;
	margin-bottom: 0px;
	background-color: $white;
	border-radius: 0;
	@include font-size(20);
	line-height: 1.3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 8.4rem;
	margin-bottom: 2.0rem;
	flex-wrap: wrap;

	@media (max-width: $md){
		padding: 1.0rem 1.6rem 1.0rem 1.6rem+1.6rem+2rem;
		@include font-size(16);
	}

	&:before{
		top: 50%;
		transform: translateY(-50%);
		left: 1.6rem;

		@media (max-width: $md){
			left: 1.6rem;
		}
	}

	&:after{
		display: none;
	}

	.button.button.button.button.button.button{
		order: 2;
		width: auto;
		margin-top: .5rem;
		margin-bottom: .5rem;
	}

	@media (max-width: $md){

		.button{
			width: 100%;
		}
	}
}

.woocommerce-message.woocommerce-message{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-error.woocommerce-error{
	border-top-color: $red;
	color: $red;

	*{
		color: $red;
	}

	&:before{
		color: $red;
	}
}

.woocommerce-info.woocommerce-info{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-view-order{

	@media (max-width: $md){

		.product-name.product-name.product-name.product-name.product-name{
			width: 100%!important;
		}
	}
}

.page-id-43{

	.woocommerce.woocommerce.woocommerce{
		padding-bottom: 10.0rem!important;
		//padding-top: 30.9rem!important;

		@media (max-width: $md){
			padding-bottom: 5.0rem!important;
			padding-top: 8.998rem!important;
		}
	}

	#add_payment_method #payment{
		padding: 1.5rem;
	}

	&.woocommerce-edit-address{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: .2rem;
		}
	}

	&.woocommerce-edit-account{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 0;
		}
	}

	.woocommerce{
		display: flex;
		flex-wrap: wrap;

		.woocommerce-MyAccount-navigation{
			width: 18.0rem;

			@media (max-width: $md){
				width: 100%;
				margin-bottom: 1em;
			}

			ul{

				@media (max-width: $md){
					margin-bottom: 0;
					border-bottom: .1rem solid $grey-light;
					display: flex;
					flex-direction: column;
				}

				&.open{

					li.is-active{
						background-image: url(../images/arrow-up.svg);
					}

					li:not(.is-active){
						display: block;
					}
				}

			}

			li{
				padding-left: 0;
				line-height: 0;

				@media (max-width: $md){

					&:nth-of-type(1){
						order: 2;
					}

					&:nth-of-type(2){
						order: 3;
					}

					&:nth-of-type(3){
						order: 4;
					}

					&:nth-of-type(4){
						order: 5;
					}

					&:nth-of-type(5){
						order: 6;
					}

					&:nth-of-type(6){
						order: 7;
					}

					&:nth-of-type(7){
						order: 8;
					}

					&:nth-of-type(8){
						order: 9;
					}

					&:nth-of-type(9){
						order: 10;
					}

					&:nth-of-type(10){
						order: 11;
					}

					&:nth-of-type(11){
						order: 12;
					}

					&.is-active{
						padding-right: 2.0rem;
						background-image: url(../images/arrow-down.svg);
						background-repeat: no-repeat;
						background-position: 100% 1.1rem;
						cursor: pointer;
						background-size: 1.9rem auto;

						a{
							pointer-events: none;
						}
					}

					&:not(.is-active){
						display: none;
					}
				}

				&.is-active{

					@media (max-width: $md){
						order: 1;
					}

					a{
						padding-left: 2.0rem;
						background-position: 0 .4em;
						background-position: 0 50%;
					}
				}

				a{
					text-decoration: none;
					line-height: 1.2em;
					display: inline-block;
					background-image: url(../images/arrow-down.svg);
					background-repeat: no-repeat;
					overflow: hidden;
					background-position: -1.7rem .4em;
					background-position: -1.7rem 50%;
					background-size: 1.1rem auto;
					padding: .4rem 0;
					color: $colour;

					@media (max-width: $md){
						line-height: 1.5;
						background-image: none;
						padding-left: 0!important;
					}

					&:hover,
					&:focus-visible{
						padding-left: 2.0rem;
						background-position: 0 .4em;
						background-position: 0 50%;


					}
				}
			}

			+ .woocommerce-MyAccount-content{
				width: calc(100% - 18.0rem);

				@media (min-width: $md + 1){
					padding-left: 2.75rem;
				}

				@media (max-width: $md){
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: $md){
	.woocommerce.woocommerce table.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive tr td,
	.woocommerce-page.woocommerce-page table.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive.shop_table_responsive tr td{
		border-left: none!important;
	}

	.woocommerce.woocommerce table.shop_table_responsive tr td::before,
	.woocommerce-page.woocommerce-page table.shop_table_responsive tr td::before{
		content: attr(data-title);
	}
}

.woocommerce-terms-and-conditions-checkbox-text{
	width: auto !important;
}
