//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid'; /* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
//@import 'pagination';
@import 'breadcrumb';
@import 'social';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'woocommerce';
//@import 'fancybox';
//@import 'tabs';

.waypoint{
	transition: .2s;
	opacity: 0;
	//visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		//visibility: visible;
		transform: translateY(0);
		transition: $wayTime 0s;
	}
}

//
//!!BLOCKS START
//

body section{

	.container{
		position: relative;
		z-index: 3;
	}

	*:last-child{
		margin-bottom: 0;
	}

	.btn-con{
		margin-top: 3.2rem;

		@media (max-width: $md){
			margin-top: 2rem;
		}
	}
}

.link-con{
	margin-top: 3.8rem;

	@media (max-width: $md){
		margin-top: 2.5rem;
	}

	.link{
		@include font-size(24);
		font-family: $alt-font;
		font-weight: 900;
		letter-spacing: .05em;
		text-decoration: none;
		display: flex;
		align-items: center;

		@media (max-width: $md){
			@include font-size(20);
			letter-spacing: 0;
		}

		i{
			aspect-ratio: 26/23;
			object-fit: cover;
			object-position: 50% 50%;
			background-image: url(../images/link-arrow.svg);
			background-repeat: no-repeat;
			background-size: 100% auto;
			display: inline-block;
			width: 2.6rem;
			margin-left: 2.7rem;
			transform: rotate(0deg);

			@media (max-width: $md){
				margin-left: 1.1rem;
			}
		}

		&:hover,
		&:focus-visible{
			color: currentColor;
			
			i{
				transform: rotate(-45deg);
			}
		}
	}
}

.hero-section{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 63.26%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 22.45%, rgba(0, 0, 0, 0) 47%), linear-gradient(0deg, rgba(203, 137, 39, 0.2), rgba(203, 137, 39, 0.2));
		background-blend-mode: plus-darker, plus-darker, normal;
	}

	video,
	img.full-bg{
		object-fit: cover;
		object-position: 50% 50%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.row{
		min-height: 87.1rem;
		align-items: flex-end;
		padding-top: 19.808rem;
		padding-bottom: 6.6rem;

		@media (max-width: $md){
			min-height: 59.8rem;
			padding-bottom: 4.8rem;
		}
	}

	*{
		color: $white;
	}
}

.intro-section{

	.row{
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;

		@media (max-width: $md){
			padding-top: 2.5rem;
			padding-bottom: 4.6rem;
		}
	}

	.text{
		width: 82rem;
	}

	p{
		
		@media (min-width: $md + 1){
			@include font-size(24);
			@include line-height(24,34);
		}
	}
}

.intro-section + .categories-section{

	.row{
		padding-top: .9rem;

		@media (max-width: $md){
			padding-top: .1rem;
		}
	}
}

.categories-section{

	&:has(+ .shop-archive-section){

		.row{
			padding-bottom: 9.5rem;

			@media (max-width: $md){
				padding-bottom: 7rem;
			}
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 1.25rem;
			padding-left: 1.25rem;
		}

		.row{
			margin-right: -1.25rem;
			margin-left: -1.25rem;
		}
	}

	.container{
		max-width: 175.5rem;
	}

	.row{
		padding-top: 5.6rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-bottom: 4.2rem;
		}
	}

	.c-item{

		.post-type-archive &{

			.top{

				&:after{
					display: none;
				}
			}
		}

		@media (min-width: $md + 1){
			
			@for $i from 1 through 5 {
				&:nth-of-type(5n + #{$i}) {
					transition-delay: $i *.1s;
				}
			}
		}
	}
}


.c-item{
	margin-bottom: 2.5rem;

	@media (min-width: $md + 1){
		flex: 0 0 20%;
		max-width: 20%;
	}

	@media (max-width: $md){
		margin-bottom: 4.5rem;
	}

	&.oil{

		.inner{

			.top{
				background-color: $orange;

				i{
					background-image: url(../images/oil.svg);
					width: 25.12rem;
					aspect-ratio: 251.2/400;

					@media (max-width: $md){
						width: 23.12rem;
					}
				}
			}
		}
	}

	&.sugar{

		.inner{

			.top{
				background-color: $blue;

				i{
					background-image: url(../images/sugar.svg);
					width: 32.6rem;
					aspect-ratio: 326/360;

					@media (max-width: $md){
						background-image: url(../images/c-sugar.svg);
						width: 29.6rem;
						top: 40%;
					}
				}
			}
		}
	}

	&.agave{

		.inner{

			.top{
				background-color: $green;

				i{
					background-image: url(../images/agave.svg);
					width: 32.6rem;
					aspect-ratio: 326/402;

					@media (max-width: $md){
						background-image: url(../images/c-agave.svg);
						width: 33rem;
						top: 48%;
					}
				}
			}
		}
	}

	&.honey{

		.inner{

			.top{
				background-color: $yellow;

				i{
					background-image: url(../images/honey.svg);
					width: 32.6rem;
					aspect-ratio: 326/360;

					@media (max-width: $md){
						background-image: url(../images/c-honey.svg);
						width: 37.6rem;
						max-width: 37.6rem;
						top: 48%;
					}
				}
			}
		}
	}

	&.syrups{

		.inner{

			.top{
				background-color: $pink;

				i{
					background-image: url(../images/syrups.svg);
					width: 32.6rem;
					aspect-ratio: 326/400;

					@media (max-width: $md){
						background-image: url(../images/c-syrups.svg);
						width: 28.6rem;
						top: 41%;
					}
				}
			}
		}
	}

	.inner{
		// background-color: pink;
		// border-radius: 2.4rem;
		// min-height: 83rem;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border-radius: 2.4rem;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				~ .top{

					&:after{
						transform: rotate(45deg);
					}

					i{
						transform: translate(-50%, -50%) scale(3);
					}

					img{
						transform: rotate(-5deg);
					}
				}
			}

			~ .top{

				&:after{
					content: '';
					position: absolute;
					aspect-ratio: 28.87/28.8701 ;
					width: 2.887rem;
					position: absolute;
					bottom: 3.5rem;
					right: 4rem;
					transform: rotate(0deg);
					background-image: url(../images/link-arrow-alt.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					transition: $time;
				}
			}
		}

		.top{
			position: relative;
			width: 100%;
			height: 56rem;
			border-radius: 2.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			@media (max-width: $md){
				height: 48rem;
			}

			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(1);
				background-size: 100% auto;
				background-repeat: no-repeat;
				transition: $time;

				@media (max-width: $md){
					top: 43%;
				}
			}

			img{
				width: 96%;
				max-width: 96%;
				transform: rotate(0deg);
				transition: $time;

				@media (max-width: $md){
					width: 76%;
					max-width: 76%;
					margin-top: -5.5rem;
				}
			}

			h3{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				@include font-size(32);
				@include line-height(32,40);
				letter-spacing: -.02em;
				padding: 0 9.3rem 2.6rem 3.3rem;

				@media (max-width: $md){
					@include font-size(29);
					@include line-height(29,40);
					padding: 0 12.8rem 2.8rem 3.3rem;
				}
			}
		}

		.bottom{
			padding: 2.4rem 3.3rem .4rem;
		}
	}
}

.stocked-by-section{
	background-color: $blue-back;

	.row{
		padding-top: 7.7rem;
		padding-bottom: 7.7rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 4.9rem;
			padding-bottom: 7.2rem;
			text-align: center;
		}
	}


	.title-side{

		@media (max-width: $md){
			margin-bottom: 4rem;
		}

		@media (min-width: $md + 1){
			flex: 0 0 25.7%;
			max-width: 25.7%;
			padding-bottom: 2.4rem;
			position: relative;
			z-index: 3;
		}
	}

	.logo-side{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (min-width: $md + 1){
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: -.1rem;
				bottom: -.1rem;
				right: 100%;
				background-color: $blue-back;
				width: 200vw;
				max-width: 200vw;
				z-index: 2;
			}
		}

		

		// @media (max-width: $md){
		// 	flex-direction: column;

		// 	img{

		// 		&:has(+ img){
		// 			margin-bottom: 5.6rem;
		// 		}
		// 	}
		// }

		@media (min-width: $md + 1){
			flex: 0 0 74.3%;
			max-width: 74.3%;
			padding-right: 5.5rem;
		}

		.stock-slide{
			pointer-events: none;

			@media (min-width: $md + 1){
				
				&.less-than{

					.swiper-slide-duplicate{
						display: none!important;
					}
				}
			}

			.swiper-wrapper-con{
				overflow: visible!important;

				@media (max-width: $md){
					overflow: visible!important;
				}
			}

			.swiper-wrapper{
				transition-timing-function: linear;
			}

			.inner{
				display: flex;
				justify-content: center;
				align-items: center;

				img{
					min-width: 10rem;
				}
			}
		}
	}
}

.text-with-images-section{

	.row{
		padding-top: 13.5rem;
		padding-bottom: 10.4rem;

		@media (max-width: $md){
			padding-top: 4.9rem;
			padding-bottom: 2.4rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 33.3%;
			max-width: 33.3%;

			.text{
				position: sticky;
				top: 13.5rem;
			}
			
		}
	}

	.image-slide-con{
		margin-top: 5rem;

		@media (min-width: $md + 1){
			max-height: 0;
			overflow: hidden;
		}

		.image-slide{
			width: calc(100% + 2.4rem);
			max-width: calc(100% + 2.4rem);
			margin-left: -1.2rem;

			.swiper-slide {
				padding: 0 1.2rem;
			}
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		img{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 2.4rem;
			width: 60rem;
		}
	}

	.image-side{

		@media (max-width: $md){
			display: none;
		}

		@media (min-width: $md + 1){
			flex: 0 0 66.7%;
			max-width: 66.7%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding-top: 6rem;
			padding-left: 16.4rem;
			padding-right: 5.8rem;
		}

		img{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 2.4rem;
			width: 60rem;

			@media (min-width: $md + 1){
				
				&:nth-of-type(odd){
					transform: rotate(-10deg);
					align-self: flex-start;

					&.waypoint{
						transform: translateY(5rem) rotate(-0deg);

						&.animate{
							transform: translateY(0) rotate(-10deg);
						}
					}

					~ img:nth-of-type(4n + 3){
						margin-top: -6.1rem;
						margin-left: -1.7rem;
					}
				}

				&:nth-of-type(even){
					transform: rotate(10deg);
					margin-top: -6.4rem;

					&.waypoint{
						transform: translateY(5rem) rotate(-0deg);

						&.animate{
							transform: translateY(0) rotate(10deg);
						}
					}

					~ img:nth-of-type(4n + 4){
						margin-top: -6.5rem;
						margin-right: -3.9rem;
					}
				}
			}
			
		}
		
	}
}

.text-with-images-section:has(+ .points-section){

	@media (max-width: $md){
		
		.image-slide-con{
			margin-top: 24.8rem;
		}
	}
}

.text-with-images-section + .points-section{

	@media (max-width: $md){
		margin-top: -56.5rem;
		pointer-events: none;

		.image-con:nth-of-type(2){
			margin-bottom: 41.5rem;
		}
	}

	.row{
		
		@media (max-width: $md){
			padding-top: 0;
		}
	}
}

.points-section{

	.row{
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
		justify-content: space-between;

		@media (max-width: $md){
			padding-top: 2.6rem;
			padding-bottom: 2.6rem;
			justify-content: center;
		}
	}

	.image-con{
		
		@media (min-width: $md + 1){
			flex: 0 0 28.9rem + 3.2rem;
			max-width: 28.9rem + 3.2rem;

			&:nth-of-type(odd){
				margin-top: 4.1rem;
			}
		}

		&.animate{

			@for $i from 1 through 5{

				&:nth-of-type(#{$i}) {
					transition-delay: #{$i*.15}s;
				}
			}
		}

		@media (max-width: $md){
			
			&:nth-of-type(odd){
				margin-top: 2.4rem;
				padding-right: 2rem;

				&:last-of-type{
					padding-right: 1.5rem;
					padding-left: 1.5rem;
				}
			}

			&:nth-of-type(even){
				padding-left: 2rem;
			}
		}

		img{
			width: 28.9rem;
		}
	}

	.points-slide{
		pointer-events: none;

		@media (min-width: $md + 1){
			
			&.less-than{

				.swiper-slide-duplicate{
					display: none!important;
				}
			}
		}

		.swiper-wrapper-con{

			@media (max-width: $md){
				overflow: visible!important;
			}
		}

		.swiper-wrapper{
			transition-timing-function: linear;
		}

		.inner{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.stocked-by-section + .what-to-eat-section{

	.row{
		padding-top: 13.6rem;

		@media (max-width: $md){
			padding-top: 7rem;
		}
	}
}
	
.what-to-eat-section{

	.row{
		padding-top: 9.5rem;
		padding-bottom: 9.5rem;
		align-items: flex-end;

		@media (max-width: $md){
			padding-top: 2.3rem;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			margin-bottom: 6.3rem;
			padding-right: 15rem;
		}

		p{

			strong{
				text-transform: lowercase;

				a{
					text-decoration: none;

					&:hover,
					&:focus-visible{
						text-decoration: underline;
					}
				}

				&:has(+ strong){

					&:after{
						content: ', ';
					}
				}
			}
		}
	}

	.btn-side{

		@media (min-width: $md + 1){
			margin-bottom: 6.3rem;
			padding-bottom: .9rem;
		}

		@media (max-width: $md){
			
			.btn-con{
				justify-content: flex-start;
			}
		}
	}

	h2{

		@media (min-width: $md + 1){
			margin-bottom: .38em;
		}
		
	}

	.recipies-slide{

		@media (max-width: $md){
			margin-top: 4.9rem;
		}

		.swiper-slide{

			// .inner{
			// 	background-color: pink;
			// 	min-height: 88rem;
			// }
		}

		.swiper-scrollbar{

			@media (max-width: $md){
				bottom: -4.9rem;
			}

			@media (min-width: $md + 1){
				display: none!important;
			}
		}
	}
}



.what-to-eat-section + .best-sellers-section,
.what-to-eat-section + .featured-product-section{

	.row{
		padding-top: 4.7rem;

		@media (max-width: $md){
			padding-top: .2rem;
		}
	}
}

.best-sellers-section,
.featured-product-section{

	&:has(+ .what-to-eat-section){

		.row{
			padding-bottom: 4.3rem;
		}

		+ .what-to-eat-section{
		
			.row{
				padding-bottom: 13.5rem;
			}
		}
	}

	.row{
		padding-top: 7.7rem;
		padding-bottom: 15.9rem;

		@media (max-width: $md){
			padding-bottom: 4.8rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 6.3rem;

		@media (max-width: $md){
			margin-bottom: 4.8rem;
		}
	}

	.best-slide{
		margin-bottom: 3.1rem;

		&:has(:not(.swiper-slide:nth-of-type(3))){

			@media (min-width: $md + 1){

				.swiper-wrapper{
					justify-content: center;
				}
			}
		}

		.swiper-slide{

		}

		.swiper-scrollbar{

			@media (max-width: $md){
				bottom: .8rem;
			}
		}
	}

	.btn-bottom{
		margin-top: -1rem;
	}
}

.featured-product-section{

	.row{
		//justify-content: center;
	}

	@media (min-width: $md + 1){
		
		.best-slide-con{
			display: none;
		}
	}

	@media (max-width: $md){
		
		.col-12.col-md-4.p-item{
			display: none;
		} 
	}
}

.text-hero-section{

	+ section{

		.row{
			padding-top: 0;
		}
	}

	.single-recipes &{

		.row{
			padding-bottom: 1.5rem;
		}

		.text{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.breadcrumb{
				width: 100%;
			}

			h1{

				@media (min-width: $md + 1){
					width: calc(100% - 50rem - 3.2rem);
				}
			}
		}
	}

	.row{
		padding-top: 20.7rem;
		padding-bottom: 6.1rem;

		@media (max-width: $md){
			padding-top: 8.998rem;
		}
	}

	.info{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
		
		@media (min-width: $md + 1){
			width: 50rem;
		}

		@media (max-width: $md){
			justify-content: flex-start;
		}

		span{
			@include font-size(18);
			font-weight: 400;
			margin: 0 0 2.4rem;

			@media (max-width: $md){
				@include font-size(16);
			}

			&:has(+ span){
				margin-right: 10.4rem;

				@media (max-width: $md){
					margin-right: 1.6rem;
				}
			}

			strong{
				font-weight: 700;
			}
		}
	}
}

.wide-image-section{

	&:has(+ .ingredients-and-directions-section){

		@media (max-width: $md){
			
			.row{
				padding-bottom: 2.5rem;
			}
		}
	}

	.row{
		padding-top: 7.1rem;
		padding-bottom: 7.1rem;

		@media (max-width: $md){
			padding-bottom: 0;

		}
	}

	img{
		width: 100%;
		border-radius: 2.4rem;

		.single-recipes &{
			aspect-ratio: 1728/800;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}

.numbered-text-with-images-section{
	counter-reset: sectionN;

	.row{
		padding-top: 17.5rem;
		padding-bottom: 5.8rem;
		align-items: center;
		

		&:nth-of-type(odd){

			.text-side{

				@media (min-width: $md + 1){
					padding-left: 16.3rem;
					padding-right: 17.3rem;
				}
			}

			.image-side{

				@media (min-width: $md + 1){
					padding-left: 2rem;
				}
			}
		}

		&:nth-of-type(even){

			.text-side{

				@media (min-width: $md + 1){
					padding-left: 16.3rem;
					padding-right: 16.9rem;
					order: 2;
				}
			}

			.image-side{

				@media (min-width: $md + 1){
					padding-left: 26.4rem;
				}

				&.waypoint{

					.image1{
						top: auto;
						bottom: 0;
					}

					.image3{
						top: 0;
						bottom: auto;
						right: 50%;
					}

					&.animate{

						.image1{
							transform: translate(-23.3rem, -19.1rem) rotate(-10deg);

							@media (max-width: $md){
								transform: translate(-1.3rem, -36.1rem) rotate(-10deg);
							}
						}
		
						.image3{
							transform: translate(25.1rem, -23.2rem) rotate(10deg);

							@media (max-width: $md){
								transform: translate(17.9rem, -20.3rem) rotate(10deg);
							}
						}
					}
				}
			}
		}

		&:last-of-type{
			padding-bottom: 8.3rem;
		}
	}

	.text-side{
		position: relative;

		&.waypoint{

			.text{
				opacity: 0;
				visibility: hidden;
				transform: translateY(5rem);
			}

			&.animate{

				.text{
					opacity: 1;
					visibility: visible;
					transform: translateY(0rem);
					transition: $wayTime $wayTime;
				}
			}
		}

		@media (min-width: $md + 1){
			padding-top: 5.5rem;
		}

		&:nth-of-type(1n + 10){

			&:before{
				content: counter(sectionN);
			}
		}

		&:before{
			counter-increment: sectionN;
			content: "0"counter(sectionN);
			display: inline-block;
			position: absolute;
			top: -.575em;
			left: .013em;
			@include font-size(560);
			line-height: 1;
			letter-spacing: -.05em;
			font-weight: 700;
			color: rgba($blue-back,.75);

			@media (max-width: $md){
				@include font-size(320);
			}
		}

		.text{
			position: relative;
			z-index: 2;
		}

		h2{
			margin-bottom: .415em;
		}
		
	}

	.image-side{

		@media (max-width: $md){
			margin-top: 24rem;
		}
		
		.img-con{
			position: relative;
			width: 60rem;
		}

		&.waypoint{

			.image1,
			.image3{
				opacity: 0;
				visibility: hidden;
			}

			&.animate{

				.image1{
					opacity: 1;
					visibility: visible;
					transition: $wayTime $wayTime;
					transform: translate(-13.8rem, -20.6rem) rotate(-10deg);

					@media (max-width: $md){
						transform: translate(-0.8rem, -19.2rem) rotate(-10deg);
					}
				}

				.image3{
					opacity: 1;
					visibility: visible;
					transition: $wayTime $wayTime;
					transform: translate(22.4rem, -18.9rem) rotate(10deg);

					@media (max-width: $md){
						transform: translate(.9rem, -35.8rem) rotate(10deg);
					}
				}
			}
		}

		img{
			border-radius: 2.4rem;

			&.image1,
			&.image2,
			&.image3{
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
			}

			&.image1,
			&.image3{
				width: 20rem;
				position: absolute;

				@media (max-width: $md){
					width: 15rem;
				}
				
			}

			
			&.image1{
				top: 0;
				left: 0;
				transform: translate(0, 0) rotate(0deg);
			}
			
			&.image2{
				width: 60rem;
				position: relative;
				z-index: 3;
			}

			&.image3{
				bottom: 0;
				right: 0;
				transform: translate(0, 0) rotate(0deg);
			}
		}
	}
}

.form-section{

	.block-intro {
		text-align: center;
		padding-bottom: 10rem;

		.breadcrumb {
			justify-content: center;
		}
	}

	h1 {
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
	}

	.row{
		padding-top: 26.2rem;
		padding-bottom: 26rem;

		@media (max-width: $md){
			padding-bottom: 7rem;
		}
	}

	.form-side {

		
	}

	.gfield_consent_label.gfield_consent_label.gfield_consent_label{
		@include font-size(20);
		color: $black;
		padding-left: 6.8rem !important;
	}

	.form-side{

		@media (min-width: $md + 1){
			padding-left: 16.3rem;
			padding-right: 17.3rem;
		}
	}

	.socials {
		margin-top: 3rem;
	}

	.social-menu {
		a {
			color: $black;
		}
	}

	.image-side{
		margin-top: 24rem;
		@media (max-width: $md){
			margin-top: 24rem;
		}
		
		.img-con{
			position: relative;
			width: 60rem;
			// margin: 0 auto;
		}

		&.waypoint{

			.image1,
			.image3{
				opacity: 0;
				visibility: hidden;
			}

			&.animate{

				.image1{
					opacity: 1;
					visibility: visible;
					transition: $wayTime $wayTime;
					transform: translate(-13.8rem, -20.6rem) rotate(-10deg);

					@media (max-width: $md){
						transform: translate(-0.8rem, -19.2rem) rotate(-10deg);
					}
				}

				.image3{
					opacity: 1;
					visibility: visible;
					transition: $wayTime $wayTime;
					transform: translate(22.4rem, -18.9rem) rotate(10deg);

					@media (max-width: $md){
						transform: translate(.9rem, -35.8rem) rotate(10deg);
					}
				}
			}
		}

		img{
			border-radius: 2.4rem;

			&.image1,
			&.image2,
			&.image3{
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
			}

			&.image1,
			&.image3{
				width: 20rem;
				position: absolute;

				@media (max-width: $md){
					width: 15rem;
				}
				
			}

			
			&.image1{
				top: 0;
				left: 0;
				transform: translate(0, 0) rotate(0deg);
			}
			
			&.image2{
				width: 60rem;
				position: relative;
				z-index: 3;
			}

			&.image3{
				bottom: 0;
				right: 0;
				transform: translate(0, 0) rotate(0deg);
			}
		}
	}
}

.recipes-archive-section{

	.row{
		padding-bottom: 21.5rem;

		@media (max-width: $md){
			padding-bottom: 7rem;
		}
	}

	.filter-side{

		@media (max-width: $md){
			margin-bottom: 4rem;
		}
	}

	.facetwp-template{
		display: flex;
		flex-wrap: wrap;
	}

	.filter{

		&:has(+ .filter){
			margin-bottom: 9rem;

			@media (max-width: $md){
				margin-bottom: 4rem;
			}
		}

		.top{
			margin-bottom: 2.3rem;

			@media (max-width: $md){
				
			}
			@media screen and (max-width: $md) {
				cursor: pointer;
				transition: all 0.6s ease-in-out 0s;
	
				&:after {
					content: "+";
					display: inline-block;
					margin-left: 8px;
				}
	
				&.open {
					&:after {
						content: "-";
					}
				}
	
				
			}
		}

		.facetwp-type-radio{
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@media (max-width: $md){
				flex-direction: row;
				flex-wrap: wrap;
				display: none;
			}

			.facetwp-radio{
				background-image: none;
				background-color: $blue;
				text-transform: uppercase;
				@include font-size(14);
				font-weight: 300;
				margin: 0 0 2.5rem;
				padding: .6rem 1.6rem .8rem 1.6rem;
				border-radius: 1.75rem;
				display: inline-block;
				transition: $time;

				@media (max-width: $md){
					margin: 0 1.6rem 1.6rem 0;
				}
				

				&:hover,
				&:focus-visible,
				&.checked{
					background-color: $pink;
					background-color: #BAEAFC;
				}
			}
		}
	}


	.recipes-side{
		padding: 0;
	}

	.r-item{
		margin-bottom: 1.6rem;
	}

	.facetwp-facet-read_more{
		display: flex;
		justify-content: center;
		margin-top: 10.7rem;

		&:has(.facetwp-hidden){
			display: none;
		}

		.facetwp-load-more{
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			font-size: 2.4rem!important;
			line-height: 1.3;
			font-weight: 900;
			color: $black!important;
			text-decoration: none!important;
			font-family: $alt-font;
			text-align: center;
			padding: 1.473rem 3.2rem;
			padding: 1.573rem 3.2rem 1.373rem 3.2rem;
			background-color: $transparent;
			border: .1rem solid $transparent;
			border-radius: 4.4rem;
			letter-spacing: .05em;
			cursor: pointer;
			justify-content: center;
			text-transform: uppercase;

			&:hover,
			&:focus-visible{
				background-color: $blue;
				border-color: $blue;
			}
		}
	}
}

.r-item{

	.inner{
		position: relative;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			@include font-size(0);
			border-radius: 100rem 100rem 0 0;

			&:hover,
			&:focus-visible{
				
				~ .img-con:after{
					opacity: 1;
				}
			}
		}

		.img-con{
			border-radius: 100rem;
			width: 100%;
			margin-bottom: 2.4rem;
			position: relative;

			@media (max-width: $md){
				margin-bottom: 1.5rem;
			}
			
			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background-color: rgba(black,.2);
				opacity: 0;
				transition: $time;
				overflow: hidden;
				border-radius: 100rem;
			}

			.simplefavorite-button{
				position: absolute;
				bottom: 3rem;
				right: 3rem;
				width: 7.6rem;
				aspect-ratio: 1/1;
				border-radius: 50%;
				background-image: url(../images/favorite.svg);
				background-repeat: no-repeat;
				@include font-size(0);
				z-index: 2;
				background-size: 100% auto;

				@media (max-width: $md){
					width: 4.8rem;
					bottom: 1.6rem;
					right: 3.2rem;
				}

				&.active,
				&:hover,
				&:focus-visible{
					opacity: 1;
					
					&:after{
						opacity: 1;
						visibility: visible;
					}
				}

				&.loading{
					opacity: .5;
					
					&:after{
						opacity: .5;
						visibility: visible;
					}
				}

				&:after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					border-radius: 50%;
					background-image: url(../images/favorite-active.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		img{
			aspect-ratio: 408/600;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 100rem;
			width: 100%;
			overflow: hidden;

			@media (max-width: $md){
				aspect-ratio: 342/440;
			}
		}

		h3{
			text-align: center;
			margin-bottom: .4em;

			@media (max-width: $md){
				margin-bottom: .45em;
			}
		}

		.info{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			span{
				@include font-size(16);
				font-weight: 300;
				margin: 0 1.6rem 2.4rem;

				@media (max-width: $md){
					@include font-size(14);
					margin: 0 .8rem 2rem;
				}
			}
		}

		.tags{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width: $md){
				padding-left: .4rem;
				padding-right: .4rem;
			}

			.tag{
				background-color: $blue;
				text-transform: uppercase;
				@include font-size(16);
				font-weight: 300;
				margin: 0 .8rem 1.6rem;
				padding: .3rem 1.6rem .5rem 1.6rem;
				border-radius: 1.6rem;
				text-decoration: none;
				z-index: 3;

				&:hover,
				&:focus-visible{
					background-color: $pink-dark;
				}
			}
		}
	}
}

.ingredients-and-directions-section{

	.row{
		padding-top: 5.7rem;
		padding-bottom: 8.5rem;

		@media (max-width: $md){
			padding-block: 0;
		}
	}

	.ingredients-side{

		.inner{
			background-color: $blue-back;
			border-radius: 2.4rem;
			padding: 4.1rem 4rem 2.4rem;

			@media (max-width: $md){
				padding: 2.3rem 2.4rem 2.3rem;
			}
		}
	}

	.directions-side{

		.inner{
			padding: 2.5rem 0 2.4rem;

			@media (min-width: $md + 1){
				padding-left: 14.6rem;
			}
		}

		.top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 6.4rem;
			
			@media (max-width: $md){
				flex-direction: column;
				align-items: flex-start;
			}

			h2{

				@media (min-width: $md + 1){
					margin-bottom: .1em;
				}
			}
		}
	}

	h4{
		margin-bottom: 2.1em;
	}

	.tags{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 4.9rem;

		.tag{
			background-color: $blue;
			text-transform: uppercase;
			@include font-size(16);
			font-weight: 300;
			margin: 0 1.6rem 1.6rem 0;
			padding: .6rem 1.4rem;
			padding: .4rem 1.4rem .8rem 1.4rem;
			border-radius: 1.8rem;
			text-decoration: none;

			&:hover,
			&:focus-visible{
				background-color: $pink-dark;
			}
		}
	}
}

.shop-archive-section{

	.row{
		padding-top: 0rem;
		padding-bottom: 19.8rem;

		@media (max-width: $md){
			padding-bottom: 7rem;
		}
	}

	.filter-side{
		display: flex;
		align-items: center;
		align-self: center;
		margin-bottom: 3.6rem;

		@media (max-width: $md){
			flex-wrap: wrap;
		}


		> span{
			margin: 0 2.4rem 2.4rem 0;
			@include font-size(18);
		}
		
		.facetwp-type-radio{
			display: flex;

			@media (max-width: $md){
				flex-wrap: wrap;
			}

			.facetwp-radio{
				background-image: none;
				background-color: $blue;
				text-transform: uppercase;
				@include font-size(14);
				font-weight: 300;
				margin: 0 2.4rem 2.4rem 0;
				padding: .6rem 1.6rem .8rem 1.6rem;
				border-radius: 1.75rem;
				display: inline-block;
				transition: $time;

				@media (max-width: $md){
					margin: 0 1.6rem 1.6rem 0;
				}

				&[data-value="oils"]{
					background-color: $orange;

					&:hover,
					&:focus-visible{
						background-color: $orange-dark;
					}
				}

				&[data-value="agave-nectar"]{
					background-color: $green;

					&:hover,
					&:focus-visible{
						background-color: $green-dark;
					}
				}

				&[data-value="honey"]{
					background-color: $yellow;

					&:hover,
					&:focus-visible{
						background-color: $yellow-dark;
					}
				}

				&[data-value="sugar-and-home-baking"]{
					background-color: $blue;

					&:hover,
					&:focus-visible{
						background-color: $blue-dark;
					}
				}

				&[data-value="syrups"]{
					background-color: $pink;

					&:hover,
					&:focus-visible{
						background-color: $pink-dark;
					}
				}

				&:hover,
				&:focus-visible,
				&.checked{
					background-color: $pink-dark;
				}

				&.checked{
					pointer-events: none;
				}
			}
		}
	}

	.sort-side{
		align-self: center;
		margin-bottom: 3.6rem;

		.facetwp-type-sort{
			display: flex;
			justify-content: flex-end;
		}

		select{
			height: 4.4rem;
			line-height: 4.4rem - .1rem - .1rem;
			width: auto;
			border-color: #D0D5DD;
			background-position: right 1.7rem top 50%;
			min-width: 32rem;
			padding-left: 1.4rem;
			letter-spacing: 0;
			margin-bottom: 2.4rem;

			@media (max-width: $md){
				width: 100%;
			}
		}
	}

	.shop-con{
		padding: 0;
	}

	.facetwp-template{
		display: flex;
		flex-wrap: wrap;
	}
}

.p-item{
	margin-bottom: 3.2rem;

	&.oils{

		.inner{

			.bottom{
				background-color: $orange;
			}

			a.over{

				&:hover,
				&:focus-visible{
					
					~ .top{
						background-color: $orange;
					}
				}
			}

			.top{

				i{
					background-image: url(../images/p-oil.svg);
					width: 25.2rem;
					aspect-ratio: 252/400;

					@media (max-width: $md){
						width: 15.4rem;
					}
				}
			}
		}
	}

	&.sugar-and-home-baking{

		.inner{

			.bottom{
				background-color: $blue;
			}

			a.over{

				&:hover,
				&:focus-visible{
					
					~ .top{
						background-color: $blue;
					}
				}
			}

			.top{

				i{
					background-image: url(../images/p-sugar.svg);
					width: 36rem;
					aspect-ratio: 360/360;

					@media (max-width: $md){
						width: 20.4rem;
					}
				}
			}
		}
	}

	&.agave-nectar{

		.inner{

			.bottom{
				background-color: $green;
			}

			a.over{

				&:hover,
				&:focus-visible{
					
					~ .top{
						background-color: $green;
					}
				}
			}

			.top{

				i{
					background-image: url(../images/p-agave.svg);
					width: 40.8rem;
					aspect-ratio: 408/402;

					@media (max-width: $md){
						width: 24.4rem;
					}
				}
			}
		}
	}

	&.honey{

		.inner{

			.bottom{
				background-color: $yellow;
			}
	
			a.over{
	
				&:hover,
				&:focus-visible{
					
					~ .top{
						background-color: $yellow;
					}
				}
			}

			.top{

				i{
					background-image: url(../images/p-honey.svg);
					width: 40rem;
					aspect-ratio: 400/347;

					@media (max-width: $md){
						width: 24.4rem;
					}
				}
			}
		}
	}

	&.syrups{

		.inner{

			.bottom{
				background-color: $pink;
			}
	
			a.over{
	
				&:hover,
				&:focus-visible{
					
					~ .top{
						background-color: $pink;
					}
				}
			}

			.top{

				i{
					background-image: url(../images/p-syrups.svg);
					width: 35.3rem;
					aspect-ratio: 353/400;

					@media (max-width: $md){
						width: 24.4rem;
					}
				}
			}
		}
	}

	.inner{

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border-radius: 2.4rem;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				~ .top{
					background-color: $orange;

					i{
						opacity: 0;
					}

					img{
						transform: rotate(-5deg);
					}
				}
			}
		}

		.top{
			position: relative;
			width: 100%;
			aspect-ratio: 1/1;
			border-radius: 2.4rem 2.4rem 0 0;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			background-color: $blue-back;
			transition: $time;

			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-size: 100% auto;
				background-repeat: no-repeat;
				opacity: 1;
				transition: $time;
			}

			img{
				width: 80%;
				max-width: 80%;
				transform: rotate(0deg);
				transition: $time;
			}

			h3{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				@include font-size(32);
				@include line-height(32,40);
				letter-spacing: -.02em;
				padding: 0 9.3rem 2.6rem 3.3rem;
			}
		}

		.bottom{
			padding: 3.2rem 3.2rem 3rem;
			border-radius: 0 0 2.4rem 2.4rem;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			background-color: $orange;

			@media (max-width: $md){
				padding: 2.3rem 2.4rem 2.3rem;
			}

			h3{
				width: 100%;
				margin-bottom: .4em;
				min-height: 3.6em;

				@media (max-width: $md){
					margin-bottom: 1em;
				}
			}

			.size,
			.price{
				@include font-size(24);

				@media (max-width: $md){
					@include font-size(16);
				}
			}

			.price{
				font-weight: 700;
			}
		}
	}
}

.category-info-section{

	.row{
		padding-bottom: 3.3rem;
	}

	.inner{
		background-color: $orange;
		border-radius: 2.4rem;
		padding: 2.7rem 45rem 2.7rem 5.6rem;
		position: relative;
		overflow: hidden;
		min-height: 22rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: $md){
			padding: 2.7rem 2.4rem 2.7rem 2.4rem;
		}

		*{
			position: relative;
			z-index: 2;
		}

		h2{
			margin-bottom: .4em;
		}

		i{
			position: absolute;
			right: -.2rem;
			top: 50%;
			transform: translateY(-50%);
			background-size: 100% auto;
			z-index: 1;
		}

		.term-oils &{
			background-color: $orange;
		
			i{
				background-image: url(../images/c-oil.svg);
				width: 15.2rem;
				aspect-ratio: 252/400;

				@media (max-width: $md){
					//width: 12rem;
					right: -5.2rem;
				}
			}
		}
	
		.term-sugar-and-home-baking &{
	
			background-color: $blue;
		
			i{
				background-image: url(../images/c-sugar.svg);
				width: 36rem;
				width: 26rem;
				aspect-ratio: 360/360;

				@media (max-width: $md){
					//width: 12rem;
					right: -14.2rem;
				}
			}
		}
	
		.term-agave-nectar &{
			background-color: $green;
	
			i{
				background-image: url(../images/c-agave.svg);
				width: 40.8rem;
				width: 30.8rem;
				aspect-ratio: 408/402;

				@media (max-width: $md){
					//width: 12rem;
					right: -15.2rem;
				}
			}
		}
	
		.term-honey &{
			background-color: $yellow;
	
			i{
				background-image: url(../images/c-honey.svg);
				width: 40rem;
				width: 30rem;
				aspect-ratio: 400/347;

				@media (max-width: $md){
					//width: 12rem;
					right: -15.2rem;
				}
			}
		}
	
		.term-syrups &{
			background-color: $pink;
			
			i{
				background-image: url(../images/c-syrups.svg);
				width: 35.3rem;
				width: 25.3rem;
				aspect-ratio: 353/400;

				@media (max-width: $md){
					//width: 12rem;
					right: -13.2rem;
				}
			}
		}
	}
}

.woocommerce-breadcrumb{
	display: none;
}

.product-single-section{

	.row{
		padding-top: 20.8rem;
		padding-bottom: 1.4rem;

		@media (max-width: $md){
			padding-top: 8.998rem;
		}
	}

	.product-slide-con.product-slide-con{
		border-radius: 2.4rem;
		background-color: $orange;

		@media (max-width: $md){
			margin-bottom: 4rem;
		}

		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			max-width: 100%;
		}

		&.oils{
			background-color: $orange;

			i{
				background-image: url(../images/c-oil.svg);
				width: 38.12rem;
				aspect-ratio: 251.2/400;

				@media (max-width: $md){
					width: 18.12rem;
				}
			}
		}
	
		&.sugar-and-home-baking{
			background-color: $blue;

			i{
				background-image: url(../images/c-sugar.svg);
				width: 55.12rem;
				aspect-ratio: 326/360;

				@media (max-width: $md){
					width: 26.6rem;
				}
			}
		}
	
		&.agave-nectar{
			background-color: $green;

			i{
				background-image: url(../images/c-agave.svg);
				width: 61.6rem;
				aspect-ratio: 326/402;

				@media (max-width: $md){
					width: 26.6rem;
				}
			}	
		}
	
		&.honey{
			background-color: $yellow;

			i{
				background-image: url(../images/c-honey.svg);
				width: 62.6rem;
				aspect-ratio: 326/360;

				@media (max-width: $md){
					width: 26.6rem;
				}
			}
		}
	
		&.syrups{
			background-color: $pink;

			i{
				background-image: url(../images/c-syrups.svg);
				width: 49.6rem;
				aspect-ratio: 326/400;

				@media (max-width: $md){
					width: 26.6rem;
				}
			}
		}
	}

	.product-slide{
	
		.swiper-slide{
			
		}

		.img-con.img-con{
			aspect-ratio: 848/809.8;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 1rem;

			img{
				max-width: 76%;
			}
		}
	}

	.info-side{

		@media (min-width: $md + 1){
			padding-left: 16.3rem;
			padding-top: 1.7rem;
		}

		.product_meta{
			display: none;
		}

		h1{
			margin-bottom: .35em
		}

		.size{
			@include font-size(40);
			font-weight: 400;
			font-family: $alt-font;
		}

		.prices{
			@include font-size(24);
			font-weight: 400;
			font-family: $alt-font;

			*{
				@include font-size(24);
				font-weight: 400;
				font-family: $alt-font;
			}
		}

		.description{
			margin-top: 2.9rem;
			margin-bottom: 3.1rem;

			*:last-child{
				margin-bottom: 0;
			}

			@media (min-width: $md + 1){
				padding-right: 15rem;
			}

			p{
				margin-bottom: 1.56em;
			}
		}

		.price-info.price-info{

			.quantity{
				margin-right: 3.2rem;
				margin-bottom: 2.3rem;
				display: flex;
				align-items: center;

				.minus,
				.plus{
					width: 4rem;
					height: 4rem;
					border-radius: 4rem;
					border: .4rem solid $black;
					position: relative;
					cursor: pointer;
					transition: $time;

					&:hover,
					&:focus-visible{
						background-color: $blue;
					}

					&:before,
					&:after{
						content: '';
						height: .4rem;
						width: 2rem;
						border-radius: 2rem;
						background-color: $black;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%) rotate(0deg);
					}

					&:after{
						transform: translate(-50%, -50%) rotate(90deg);
					}
				}

				.minus{
					margin-right: 1.6rem;

					&:after{
						display: none;
					}
				}

				.plus{
					margin-left: 1.6rem;
				}

				[type="number"]{
					@include font-size(20);
					font-weight: 500;
					color: $black;
					text-align: center;
					width: 17.6rem;
					border-radius: 2rem;
					border: .4rem solid $black;
					padding: 0 .2rem;
					height: 4rem;
					line-height: 4rem - .4rem - .4rem;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				input[type=number] {
					-moz-appearance: textfield;
				}
			}

			.cart{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			.product_meta{
				display: none;
			}
		}
		
	}
}

.nutritional-facts-section{

	.row{
		padding-top: 8rem;
		padding-bottom: 12.2.4rem;
	}

	.image-side{

		@media (max-width: $md){
			margin-bottom: 4rem;
		}
		
		.img-con{
			position: relative;
			width: 60rem;
			margin-left: auto;
			margin-top: 17.7rem;
		}

		&.waypoint{

			.image1,
			.image3{
				opacity: 0;
				visibility: hidden;
			}

			&.animate{

				.image1{
					opacity: 1;
					visibility: visible;
					transition: $wayTime $wayTime;
					transform: translate(-22.3rem, -19.1rem) rotate(-10deg);

					@media (max-width: $md){
						transform: translate(-0.3rem, -37.1rem) rotate(-10deg);
					}
				}

				.image3{
					opacity: 1;
					visibility: visible;
					transition: $wayTime $wayTime;
					transform: translate(25.1rem, -22.3rem) rotate(10deg);

					@media (max-width: $md){
						transform: translate(17.4rem, -22.3rem) rotate(10deg);
					}
				}
			}
		}

		img{
			border-radius: 2.4rem;

			&.image1,
			&.image2,
			&.image3{
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
			}

			&.image1,
			&.image3{
				width: 20rem;
				position: absolute;

				@media (max-width: $md){
					width: 15rem;
				}
				
			}

			
			&.image1{
				bottom: 0;
				transform: translate(0, 0) rotate(0deg);
			}
			
			&.image2{
				width: 60rem;
				position: relative;
				z-index: 3;
			}

			&.image3{
				top: 0;
				right: 50%;
				transform: translate(0, 0) rotate(0deg);
			}
		}
	}

	.facts-side{

		@media (min-width: $md + 1){
			padding-left: 16.3rem;
			padding-top: 2.4rem;
		}

		h2{
			margin-bottom: 1.12em;
		}

		table{
			
			tbody{

				td{
					@include font-size(16);
				}
			}
		}
	}
}


.woocommerce-notices-wrapper{

	&:has(.woocommerce-message){

		+ .product{

			.product-single-section{

				.row{
					padding-top: 0rem;
				}
			}
		}

		+ .text-hero-section{

			.row{
				padding-top: 0rem;
			}
		}
	}
}

.woocommerce{

	.woocommerce-cart-form{
		margin-bottom: 13.7rem;

		@media (max-width: $md){
			margin-bottom: 9rem;
		}
	}

	.shop_table.shop_table.shop_table.shop_table.shop_table.shop_table{
		border-top: .1rem solid #DBDBDB!important;
		border-radius: 0!important;
		margin-bottom: 4.7rem!important;
	}

	.cart-collaterals .cart_totals.cart_totals.cart_totals{

		@media (min-width: $md + 1){
			width: 49.1%;
		}
	}

	.product-quantity{

		.quantity{
			display: flex;
			align-items: center;

			.minus,
			.plus{
				width: 2.4rem;
				height: 2.4rem;
				border-radius: 4rem;
				border: .2rem solid $black;
				position: relative;
				cursor: pointer;
				transition: $time;

				&:hover,
				&:focus-visible{
					background-color: $blue;
				}

				&:before,
				&:after{
					content: '';
					height: .2rem;
					width: 1.115rem;
					border-radius: 2rem;
					background-color: $black;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(0deg);
				}

				&:after{
					transform: translate(-50%, -50%) rotate(90deg);
				}
			}

			.minus{
				margin-right: 1.6rem;

				&:after{
					display: none;
				}
			}

			.plus{
				margin-left: 1.6rem;
			}

			[type="number"]{
				@include font-size(14);
				font-weight: 500;
				color: $black;
				text-align: center;
				width: 20.6rem;
				border-radius: 2rem;
				border: .2rem solid $black;
				padding: 0 .2rem;
				height: 3.2rem;
				line-height: 3.2rem - .2rem - .2rem;
				text-align: center!important;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			input[type=number] {
				-moz-appearance: textfield;
			}
		}
	}
}

.four-zero-four-section{
	
	.row{
		text-align: center;
		padding-top: 19rem;
		padding-bottom: 19rem;
		align-items: center;

		@media (min-width: $md + 1){
			min-height: calc(100dvh - 52.2969rem);
		}
	}
}


.simple-text-section{

	.row{
		padding-top: 7rem;
		padding-bottom: 7rem;
	}
}

















//
//!!BLOCKS END
//

@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container{
		padding-right: 2.4rem;
		padding-left: 2.4rem;
	}

	.row{
		margin-right: -1rem;
		margin-left: -1rem;
	}
}